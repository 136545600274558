import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { GET_EMPLOYEE } from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
import { useProfileData } from "../../Components/Hooks/UserHooks";
const api = new APIClient();

// function Checkbox({ name, value = false, updateValue = () => { }, children }) {
//   const handleChange = () => {
//     updateValue(!value, name);
//   };
//   return (
//     <div>
//       <input
//         type="checkbox"
//         id={`${name}-checkbox`}
//         name={name}
//         checked={value}
//         onChange={handleChange}
//       />
//       <label htmlFor={`${name}-checkbox`} className="ml-1 capitalize">
//         {children}
//       </label>
//     </div>
//   );
// }

const ModalManageUser = ({
  addTeamModal,
  setAddTeamModal,
}) => {
  const [selecteded, setSelecteded] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);

  const { profileData } = useProfileData()

  const createteam_grid = () => {
    setAddTeamModal(!addTeamModal);
  };

  useEffect(() => {
    // Select all employees by default on initial render
    const allEmployeeIds = employeeData.map((employee) => employee._id);
    setSelecteded(allEmployeeIds);
  }, [employeeData]);





  useEffect(() => {
    getEmployeeData();
  }, []);

  const getEmployeeData = async () => {
    try {
      const response = await api.get(`${GET_EMPLOYEE}`);
      if (response.success === true) {
        setEmployeeData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <Modal
      size="xl"
      isOpen={addTeamModal}
      toggle={() => {
        createteam_grid();
      }}
      scrollable={true}
      id="exampleModalScrollable"
    >
      <ModalHeader
        className="bg-light p-3"
        id="exampleModalScrollableTitle"
        toggle={() => {
          setAddTeamModal(false);
        }}
      >
        Manage Employee
      </ModalHeader>
      {employeeData.length > 0 ? (
        <>
          <ModalBody>
            <div className="snheader-main-modal">
              {/* <Checkbox
                name="all"
                value={selecteded.length === employeeData.length}
                updateValue={selectAllS}
              >
                {" "}
                &nbsp;Select All Employee
              </Checkbox> */}
              <p>All Employee</p>
              <div>
                <p>Selected {employeeData.length} out of {profileData?.organizationStrength}</p>
              </div>
            </div>
            <Row className="mt-3">
              <Col className="col-12">
                <Row className="row-cols-xxl-5 row-cols-lg-3 row-cols-1">
                  {employeeData.map((item, index) => {
                    const idValue = item?._id;
                    return (
                      <React.Fragment key={item.id || index}>
                        <Col>
                          <Card
                            className="card-body"
                            style={{ border: "1px solid #EB98" }}
                          >
                            <div style={{ textAlign: 'center' }}>
                              <div className="d-flex mb-0 align-items-center">
                                {/* <Checkbox
                                name={idValue}
                                value={selecteded.includes(idValue)}
                                updateValue={handleSelectS}
                              > */}
                                <div className="flex-grow-1 ms-2">
                                  <h5 className="card-title mb-1">
                                    {item?.fullName}
                                  </h5>
                                </div>
                                {/* </Checkbox> */}
                              </div>
                              <h6 className="mb-0">
                                {item?.mobileNumber}
                              </h6>
                            </div>
                          </Card>
                        </Col>
                      </React.Fragment>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </ModalBody>
          {/* <div className="modal-footer">
            <Button
              type="button"
              color="danger"
              className="ms-1"
              onClick={handleClick}
            >
              Apply
            </Button>
            <Button
              color="light"
              onClick={() => {
                setAddTeamModal(false);
              }}
            >
              Close
            </Button>
          </div>{" "} */}
        </>
      ) : (
        <p style={{ textAlign: 'center', padding: '60px 0 60px 0' }}>You don't have any active employees.</p>
      )}
    </Modal>
  );
};

export default ModalManageUser;
