import React, { useEffect, useState, useCallback } from "react";
import { Label } from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import {
  EMPLOYEE_GET_EMPLOYEE,
  EMPLOYEE_GET_TEAM_EMPLOYEE_INCLUDE,
  GET_EMPLOYEE,
  GET_TEAM_EMPLOYEE_INCLUDE,
} from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
import { ToastContainer } from "react-toastify";
const api = new APIClient();

const EmployeeSelect = ({
  EmployeeDefault,
  setEmployeeDefault,
  teamIndu,
  setSelectedEmployeeOptions,
  selectedEmployeeOptions,
  labelContent,
  error,
  setError
}) => {
  const storedValue = JSON.parse(localStorage.getItem("logintype"));
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const viewReportPermission = permissionType?.includes("VIEW_REPORT");


  // const [dataFetched, setDataFetched] = useState(false);

  // const teamType = teamIndu?.map((item) => item?.value);

  const fetchEmployeeData = useCallback(
    async () => {
      try {
        let response;
        if (storedValue === "employee" && permissionType.length > 0) {
          response = await api.get(EMPLOYEE_GET_EMPLOYEE);
        } else if (storedValue === "organization") {
          response = await api.get(GET_EMPLOYEE);
        }
        if (response?.success === true) {
          const apiResponse = response.data;
          const arnOption = (apiResponse || []).map((employee) => ({
            id: `${employee?._id}`,
            value: employee?.fullName?.trim(),
            label: employee?.fullName?.trim(),
          }));
          setEmployeeDefault(arnOption);
          setSelectedEmployeeOptions(arnOption);
          // setDataFetched(true);
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    },
    [storedValue, setEmployeeDefault, setSelectedEmployeeOptions]
  );


  const fetchEEmployeeData = useCallback(
    async (teamType) => {
      try {
        let response;
        const data = { teamName: teamType };
        if (storedValue === "employee") {
          response = await api.create(EMPLOYEE_GET_TEAM_EMPLOYEE_INCLUDE, data);
        } else if (storedValue === "organization") {
          response = await api.create(GET_TEAM_EMPLOYEE_INCLUDE, data);
        }
        if (response?.success === true) {
          const result = response.data;
          const apiResponse = result.sort((a, b) => a.fullName.localeCompare(b.fullName));
          const arnOption = (apiResponse || []).map((employee) => ({
            id: `${employee?._id}`,
            value: employee?.fullName?.trim(),
            label: employee?.fullName?.trim(),
          }));
          setEmployeeDefault(arnOption);
          setSelectedEmployeeOptions(arnOption);
          //   setDataFetched(true);
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    },
    [storedValue, teamIndu, setEmployeeDefault, setSelectedEmployeeOptions]
  );



  useEffect(() => {
    const teamType = teamIndu?.map((item) => item?.value);
    if (teamType?.length > 0) {
      fetchEEmployeeData(teamType);
    } else {
      fetchEmployeeData()
    }
    // if (!dataFetched) {
    //   fetchEmployeeData();
    // }
  }, [teamIndu]);

  const validateSelection = (selectedOptions) => {
    if (selectedOptions.length === 0) {
      setError("You must select at least one employee.");
    } else {
      setError("");
    }
  };


  const handleSelectChange = (selectedOptions) => {
    setSelectedEmployeeOptions(selectedOptions);
    validateSelection(selectedOptions);
  };





  return (
    <>
      <ToastContainer />
      <Label>{labelContent}</Label>
      <MultiSelect
        disabled={!viewReportPermission && storedValue === "employee" ? true : storedValue === "organization" ? false : false}
        options={EmployeeDefault}
        value={selectedEmployeeOptions}
        onChange={handleSelectChange}
        labelledBy={"Select"}
        isCreatable={true}
        overrideStrings={{ selectSomeItems: "Select Employee" }}
        hasSelectAll={true}
        className="classgroup"
      // onMenuToggle={(isOpen) => {
      //   if (isOpen) {
      //     handleFetchEmployeeData();
      //   }
      // }}
      />
      {error && <small className="sales-ninja-select-maltipale-lable" style={{ color: "red", position: 'absolute',bottom:'2px',left:'22px' }}><span></span>{error}</small>}
    </>
  );
};

export default EmployeeSelect;
