import { ADD_TEAM_MEMBER_FAILURE, ADD_TEAM_MEMBER_REQUEST, ADD_TEAM_MEMBER_SUCCESS, DELETE_TEAM_DATA, DELETE_TEAM_DATA_FAIL, DELETE_TEAM_DATA_SUCCESS, UPDATE_TEAM_MEMBER_FAILURE, UPDATE_TEAM_MEMBER_REQUEST, UPDATE_TEAM_MEMBER_SUCCESS } from "./actionType"


export const addTeamMemberRequest = (member, permission, logintype) => ({
  type: ADD_TEAM_MEMBER_REQUEST,
  payload: { member, permission, logintype }
});

export const addTeamMemberSuccess = (member) => ({
  type: ADD_TEAM_MEMBER_SUCCESS,
  payload: member,
});

export const addTeamMemberFailure = (error) => ({
  type: ADD_TEAM_MEMBER_FAILURE,
  payload: error,
});

export const deleteTeamData = data => ({
  type: DELETE_TEAM_DATA,
  payload: data,
});

export const deleteTeamDataSuccess = data => ({
  type: DELETE_TEAM_DATA_SUCCESS,
  payload: data,
});

export const deleteTeamDataFail = error => ({
  type: DELETE_TEAM_DATA_FAIL,
  payload: error,
});


export const updateTeamMemberRequest = (updatedTeam) => ({
  type: UPDATE_TEAM_MEMBER_REQUEST,
  payload: {updatedTeam}
});

export const updateTeamMemberSuccess = (response) => ({
  type: UPDATE_TEAM_MEMBER_SUCCESS,
  payload: response,
});

export const updateTeamMemberFailure = (error) => ({
  type: UPDATE_TEAM_MEMBER_FAILURE,
  payload: error,
});