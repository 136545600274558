import React from "react";
import { Col, FormFeedback, Label, Modal, ModalBody, ModalHeader, Row, Form, Input, ModalFooter } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UPDATE_ROLE_MANAGEMENT } from "../../../helpers/url_helper";
import { APIClient } from '../../../helpers/api_helper';
import Toast from "../../../Components/Common/Toast";
import { ToastContainer } from "react-toastify";
import { HandleErrorMessage } from "../../../Components/Common/HandleErrorMessage";
const api = new APIClient();

const UpdateRoleManagement = ({ getUpdateRole, getRoleManagementData, updateModal_grid, setUpdateModal_grid }) => {

    const createtog_grid = () => {
        setUpdateModal_grid(!updateModal_grid);
    };

    const formiks = useFormik({
        initialValues: {
            roleUpdateName: getUpdateRole || "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            roleUpdateName: Yup.string().required("Please Enter Your Role Name"),
        }),
        onSubmit: async (values) => {
            try {
                let data = {
                    hierarchyName: getUpdateRole,
                    updateHierarchyName: values?.roleUpdateName
                };
                const response = await api.patch(`${UPDATE_ROLE_MANAGEMENT}`, data);
                if (response.success && response.success === true) {
                    if (response.success === true) {
                        Toast(response?.message, "success");
                        setTimeout(() => {
                            setUpdateModal_grid(false);
                            getRoleManagementData()
                            formiks.resetForm();
                        }, 2000);
                    } else if (response.success === false) {
                        Toast(response?.messager, "error");
                        setTimeout(() => {
                            setUpdateModal_grid(false);
                            formiks.resetForm();
                        }, 2000);
                    }
                }
            } catch (error) {
                HandleErrorMessage(error);
            }
        },
    });


    return (
        <>
            <ToastContainer />
            <Row>
                <Modal
                    isOpen={updateModal_grid}
                    toggle={() => {
                        createtog_grid();
                    }}
                >
                    <ModalHeader
                        className="bg-light p-3"
                        id="exampleModalLabel"
                        toggle={() => {
                            createtog_grid();
                        }}
                    >
                        {" "}
                        Role Updated{" "}
                    </ModalHeader>
                    <Form
                        onSubmit={formiks.handleSubmit}
                        className="needs-validation"
                        action="#"
                    >
                        <ModalBody>
                            <Row>
                                <Col sm={12}>
                                    <Label htmlFor="roleName" className="form-label">
                                        Role Name
                                    </Label>
                                    <Input
                                        name="roleUpdateName"
                                        type="text"
                                        placeholder="Enter Role Name"
                                        onChange={formiks.handleChange}
                                        onBlur={formiks.handleBlur}
                                        value={formiks.values.roleUpdateName}
                                        invalid={
                                            formiks.touched.roleUpdateName && formiks.errors.roleUpdateName
                                                ? true
                                                : false
                                        }
                                    />
                                    {formiks.touched.roleUpdateName && formiks.errors.roleUpdateName ? (
                                        <FormFeedback type="invalid">
                                            <div>{formiks.errors.roleUpdateName}</div>
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <button type="submit" className="btn btn-danger" id="add-btn">
                                    Update Role
                                </button>
                            </div>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Row>
        </>
    )
}

export default UpdateRoleManagement