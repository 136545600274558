import React from 'react'
import instagram from "../../assets/images/instagram.png"
const Insta = () => {
  return (
    <>
       <img src={instagram} alt="logo" />
    </>
  )
}

export default Insta
