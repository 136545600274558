import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Container, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import CreateRoleManagement from "./CreateRoleManagement";
import axios from "axios";
import { DELETE_ROLE_MANAGEMENT, GET_ROLE_MANAGEMENT } from "../../../helpers/url_helper";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import UpdateRoleManagement from "./UpdateRoleManagement";
import moment from "moment";
import DeleteModal from "../../../Components/Common/DeleteModal";
import SimpleBar from "simplebar-react";
import CommanDataTable from "../../../Components/Common/DataTable";
import { APIClient } from '../../../helpers/api_helper';
import Toast from "../../../Components/Common/Toast";
import { ToastContainer } from "react-toastify";
import { HandleErrorMessage } from "../../../Components/Common/HandleErrorMessage";
import PermissionsTree from "./treeCustom";
import { toCamelCase } from "../../../Components/Common/ToCamelCase";
const api = new APIClient();

const RoleManagement = () => {
  const [isLoading, setIsLoading] = useState(true);
  document.title = "Role Management";
  const [searchText, setSearchText] = useState('');
  const [filterData, setFilteredData] = useState([])
  const [getRoleData, setGetRoleData] = useState([])
  const [createmodal_grid, setCreatemodal_grid] = useState(false);
  const [updateModal_grid, setUpdateModal_grid] = useState(false);
  const [getUpdateRole, setGetUpdateRole] = useState()
  const [isRight, setIsRight] = useState(false);

  // To dos
  const [todo, setTodo] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  // rolewise data get 
  const [rolewiseData, setRoleWiseData] = useState([])


  // Fetch role management data and process it
  const getRoleManagementData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`${GET_ROLE_MANAGEMENT}`);
      if (response.success) {
        const permissionsData = response.data;
        setGetRoleData(permissionsData);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getRoleManagementData();
  }, []);


  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // ADD EMPLOYEE
  const createModal = () => {
    setCreatemodal_grid(true);
  };


  const updateModal = (updateData) => {
    setUpdateModal_grid(true)
    setGetUpdateRole(updateData)
  }

  // Delete To do
  const onClickTodoDelete = (todo) => {
    setTodo(todo);
    setDeleteModal(true);
  };


  const handleDeleteTodo = async () => {
    if (todo) {
      try {
        let data = {
          hierarchyName: todo,
        };
        let config = {
          method: "delete",
          url: `${DELETE_ROLE_MANAGEMENT}`,
          data
        };
        const response = await axios(config);
        if (response.success === true) {
          Toast(response?.message, "success");
          setTimeout(() => {
            setDeleteModal(false);
            getRoleManagementData()
          }, 2000);
        }
      } catch (error) {
        HandleErrorMessage(error);
      }
    }
  };


  const columns = [
    {
      name: <span className='font-weight-bold fs-13'> SN.</span>,
      width: "55px",
      selector: (row, index) => index + 1,
    },
    {
      name: <span className='font-weight-bold fs-13'>Role Name</span>,
      selector: row => toCamelCase(row.name),
      cell: row => <span style={{ textTransform: 'capitalize' }}>{row.name}</span>,
      sortable: true
    },

    {
      name: <span className='font-weight-bold fs-13'>Created Date</span>,
      selector: row => moment(row.createdAt).format('DD  MMM  YYYY'),
      sortable: true
    },
    // {
    //   name: <span className='font-weight-bold fs-13'>Updated Date</span>,
    //   selector: row => moment(row.updatedAt).format('DD  MMM  YYYY'),
    //   sortable: true
    // },

    {
      name: <span className='font-weight-bold fs-13'>Action</span>,
      selector: row => (
        <>
         <span className="sn-role-managment-responsive-btn">
         <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(row.name)}>
            <i className="ri-delete-bin-5-fill align-bottom" />
          </button>&nbsp;&nbsp;
          <button className="btn btn-sm btn-soft-success edit-list" onClick={() => updateModal(row.name)}>
            <i className="ri-pencil-fill align-bottom" />
          </button>&nbsp;&nbsp;
          <button className="btn btn-sm btn-soft-info edit-list" onClick={() => toggleRightCanvas(row)}>
            <i className="ri-user-follow-line align-bottom" />
          </button>
         </span>
        </>
      ),
    },
  ];


  const toggleRightCanvas = (data) => {
    setRoleWiseData(data)
    setIsRight(!isRight);
  };


  useEffect(() => {
    const filtered = (getRoleData || []).filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [getRoleData, searchText]);




  return (
    <React.Fragment>
      <ToastContainer />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTodo()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content sn-role-managment-page">
        <Container fluid>
          <BreadCrumb title="Role Management" pageTitle="Tables" />
          <Row>
            <Col lg={12}>
              <Card className='h-100'>
                <CardHeader>
                  <Button color="danger" onClick={createModal}><i className="ri-add-fill me-1 align-bottom"></i>Add Role</Button>
                </CardHeader>
                <CardBody className="sn-team-managment-tble mt-0 pt-0">
                  <CommanDataTable handleSearch={handleSearch} column={columns} tableData={filterData} isLoading={isLoading} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}
            id="offcanvasRight"
            className="border-bottom"
            style={{ width: '700px' }}
          >
            <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasRightLabel">
              Set Permissions
            </OffcanvasHeader>
            <OffcanvasBody className="p-0 pb-4 overflow-auto">
              <SimpleBar>
                <PermissionsTree rolewiseData={rolewiseData} setIsRight={setIsRight} getRoleManagementData={getRoleManagementData} />
              </SimpleBar>
            </OffcanvasBody>
          </Offcanvas>

          <CreateRoleManagement
            getRoleManagementData={getRoleManagementData}
            createmodal_grid={createmodal_grid}
            setCreatemodal_grid={setCreatemodal_grid}
          />
          <UpdateRoleManagement getRoleManagementData={getRoleManagementData}
            getUpdateRole={getUpdateRole}
            updateModal_grid={updateModal_grid}
            setUpdateModal_grid={setUpdateModal_grid} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RoleManagement;
