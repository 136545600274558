import React from 'react'
import { Input, Label } from "reactstrap";
const SearchField = ({ onChange,labelContent }) => {
    return (
        <>
            <Label for="exampleDate">{labelContent}</Label>
            <div className="search-box">
                <Input
                    type="text"
                    className="form-control search"
                    placeholder="Search By Phone No / Name"
                    onChange={onChange}
                />
                <i className="ri-search-line search-icon"></i>
            </div>
        </>
    )
}

export default SearchField