import Flatpickr from "react-flatpickr";
import { Label } from 'reactstrap'
import React, { useRef } from 'react';
import "@fortawesome/fontawesome-free/css/all.min.css";
const DatePicker = ({ onChange, getDate, labelContent }) => {
    return (
        <>
            <Label for="exampleDate">{labelContent}</Label>
            <Flatpickr
                // data-enable-time
                className="form-control"
                value={getDate}
                options={{
                    enableTime: true,
                    // dateFormat: "d M, Y h:i K",
                    dateFormat: "Y-m-d h:i K",
                    // time_12hr: true,
                }}
                onChange={onChange}
            />
        </>
    );
};

const DatePickerWithoutTimes = ({ onChange, getDate, labelContent }) => {
    return (
        <>
            <Label for="exampleDate">{labelContent}</Label>
            <Flatpickr
                className="form-control"
                value={getDate}
                options={{
                    dateFormat: "d M, Y"
                }}
                onChange={onChange}
            />
        </>
    );
};

const DatePickerFutureSub = ({ onChange, selectedFromDate, labelContent, incExperiDatePlan }) => {
    return (
        <>
            <Label for="exampleDate">{labelContent}</Label>
            <Flatpickr
                className="form-control"
                value={selectedFromDate}
                options={{
                    defaultDate: incExperiDatePlan,
                    minDate: incExperiDatePlan,
                    dateFormat: "Y-m-d",
                }}
                onChange={onChange}
            />
        </>
    );
};

const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
}


const FlatDatepickr = ({ name, value, onChange, error, touched }) => {
    const flatpickrRef = useRef(null);

    return (
        <>
            <div className="date-picker-wrapper input-group">
                <Flatpickr
                    ref={flatpickrRef}
                    className={`form-control ${touched && error ? 'is-invalid' : ''}`}
                    options={{
                        dateFormat: 'Y-m-d',
                        noCalendar: false,
                    }}
                    value={value}
                    onChange={(dates) => {
                        onChange(name, Array.isArray(dates) && dates.length ? dates[0].toISOString() : '');
                    }}
                />
                <span className="input-group-text" onClick={() => flatpickrRef.current.flatpickr.open()}>
                    <i className="fas fa-calendar-alt"></i>
                </span>
            </div>
            {touched && error ? (
                <div className="invalid-feedback" style={{ display: 'block' }}>{error}</div>
            ) : null}
        </>
    );
};

export { DatePicker, DatePickerWithoutTimes, DatePickerFutureSub, isValidDate, FlatDatepickr };
