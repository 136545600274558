import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ActionButton = ({
  label,
  onClick,
  color = 'primary',
  className = '',
  disabled = false
}) => {
  return (
    <Button
      color={color}
      className={className}
      onClick={onClick}
      disabled={disabled}
    > <i className="ri-add-fill me-1 align-bottom"></i>
      {label}
    </Button>
    
  );
};

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

ActionButton.defaultProps = {
  color: 'primary',
  className: '',
  disabled: false,
};

export default ActionButton;








