import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTeamMemberRequest,
  deleteTeamData,
  updateTeamMemberRequest,
} from "../../store/teams/action";
import ActionButton from "../../Components/Common/ActionButton";
import CommonModal from "../../Components/Common/CommonModal";
import { filterItemsBySearchText, ValidationSchema } from "../../Components/Common/validationSchema";
import DeleteModal from "../../Components/Common/DeleteModal";
import PropTypes from "prop-types";
import MemoizedDialog from "../../Components/Common/Dialog";
import { ToastContainer } from "react-toastify";
import Toast from "../../Components/Common/Toast";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import { APIClient } from "../../helpers/api_helper";
import { ADD_TO_EMPLOYEE_TEAM, EMPLOYEE_ADD_TO_EMPLOYEE_TEAM, EMPLOYEE_REMOVE_EMPLOYEE_TEAM, REMOVE_EMPLOYEE_TEAM } from "../../helpers/url_helper";
import { toCamelCase } from "../../Components/Common/ToCamelCase";
const api = new APIClient();

const TeamMemberModal = ({
  teamMem,
  getTeamData,
  setIsModalOpen,
  todo,
  isModalOpen,
  setDeleteModal,
  deleteModal,
  updatedPermission,
  //add 
  AddTeamPermision,
  addemployeeData,
  getTeamID,
  addTeamModal,
  setAddTeamModal,
  //remove
  removeemployeeData,
  RemoveFromTeamPermission,
  removeTeamID,
  setRemoveTeamModal,
  removeTeamModal

}) => {
  const dispatch = useDispatch();
  const permissionType = JSON.parse(localStorage.getItem("permission")) || [];
  const loginType = JSON.parse(localStorage.getItem("logintype")) || "";
  const createPermission = permissionType.includes("CREATE_TEAM");

  // add employee team 
  const [selectedEmployees, setSelectedEmplyees] = useState([]);
  const [searchText, setSearchText] = useState("");

  // remove employee team
  const [searchTextRemove, setSearchTextRemove] = useState("");
  const [selectedEmployeesremove, setSelectedEmplyeesRemove] = useState([]);


  const { teamData, success } = useSelector((state) => ({
    teamData: state.teamReducer.team,
    success: state.teamReducer.success,
  }));

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const toggle = () => setIsModalOpen(!isModalOpen);
  const Addtoggle = () => setIsAddModalOpen(!isAddModalOpen);

  // add and updated
  const formik = useFormik({
    initialValues: {
      teamName: "",
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: useCallback(
      (values, { setSubmitting }) => {
        const newTeam = { teamName: values?.teamName };
        const updateTeam = { teamName: values?.teamName, teamId: teamMem?.id };
        if (isAddModalOpen) {
          dispatch(addTeamMemberRequest(newTeam, createPermission, loginType));
        } else {
          if (teamMem) {
            dispatch(
              updateTeamMemberRequest({
                ...updateTeam,
                updatedPermission: updatedPermission,
                logintype: loginType,
              })
            );
          }
        }
        setSubmitting(false);
      },
      [
        isAddModalOpen,
        createPermission,
        loginType,
        teamMem,
        updatedPermission,
        dispatch,
      ]
    ),
  });


  // add team function
  const handleAddMember = useCallback(() => {
    formik.resetForm();
    formik.setFieldValue("teamName", "");
    setIsAddModalOpen(true);
  }, [formik]);


  // delete team function
  const handleDeleteTeamData = useCallback(() => {
    if (todo) {
      let team = { teamName: todo?.teamName };
      dispatch(deleteTeamData(team));
      setDeleteModal(false);
    }
  }, [todo, dispatch, setDeleteModal]);




  useEffect(() => {
    if (teamMem) {
      formik.setFieldValue("teamName", teamMem.name);
    }
  }, [teamMem]);



  useEffect(() => {
    if (success) {
      if (isAddModalOpen) {
        setIsAddModalOpen(false);
      }
      if (isModalOpen) {
        setIsModalOpen(false);
      }
      if (deleteModal) {
        setDeleteModal(false);
      }
      formik.resetForm();
      getTeamData();
      dispatch({ type: "RESET_SUCCESS_STATE" });
    }
  }, [
    success,
    deleteModal,
    isAddModalOpen,
    isModalOpen,
    dispatch,
    getTeamData,
  ]);


  const handleSearch = (setter) => (e) => {
    setter(e.target.value);
  };


  // add employee team
  const columns = [
    {
      name: <span className='font-weight-bold fs-13'>Employee Name</span>,
      selector: row => toCamelCase(row?.fullName),
      cell: row => (
        <div className='mt-2 mb-2'><span style={{ textTransform: 'capitalize' }}>{row?.fullName}</span><br /></div>
      ),
      sortable: true
    },
    {
      name: <span>Team Name</span>,
      selector: row => row?.team,
      cell: row => (
        <div className='mt-2 mb-2'>
          {row?.team?.map((callDate, index) => (
            <React.Fragment key={index}>
              <span style={{ textTransform: 'capitalize' }}>{callDate}</span><br />
              {index !== row?.team?.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      ),
    },

  ];


  // remove employee team 
  const column = [
    {
      name: <span className='font-weight-bold fs-13'>Full Name</span>,
      selector: row => toCamelCase(row?.fullName),
      cell: row => <span style={{ textTransform: 'capitalize' }}>{row.fullName}</span>,
      sortable: true
    },
    {
      name: <span>Team Name</span>,
      selector: row => (
        <div className='mt-2 mb-2'>
          {row?.team?.map((callDate, index) => (
            <React.Fragment key={index}>
              <span style={{ textTransform: 'capitalize' }}>{callDate}</span><br />
              {index !== row?.team?.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      ),
    },
  ];




  // ADD EMPLOYEE_TEAMS_API 
  const addEmployeesInTeams = useCallback(async () => {
    try {
      const data = {
        teamId: getTeamID,
        arrayOfId: selectedEmployees
      };
      let response;
      if (loginType === 'employee' && AddTeamPermision) {
        response = await api.put(`${EMPLOYEE_ADD_TO_EMPLOYEE_TEAM}`, data);
      } else if (loginType === 'organization') {
        response = await api.put(`${ADD_TO_EMPLOYEE_TEAM}`, data);
      }
      if (response && response?.success === true) {
        Toast(response?.message, "success");
        setTimeout(() => {
          setAddTeamModal(false);
          getTeamData();
        }, 2000);
      }
    } catch (error) {
      HandleErrorMessage(error);
    }
  }, [getTeamID, selectedEmployees, loginType, AddTeamPermision, setAddTeamModal, getTeamData]);



  // Remove EMPLOYEE_TEAMS_API 
  const RemoveEmployeeTeam = async () => {
    const data = {
      teamId: removeTeamID?._id,
      arrayOfId: selectedEmployeesremove
    };
    try {
      let response;
      if (loginType === 'employee' && RemoveFromTeamPermission) {
        response = await api.delete(`${EMPLOYEE_REMOVE_EMPLOYEE_TEAM}`, { data });
      } else if (loginType === 'organization') {
        response = await api.delete(`${REMOVE_EMPLOYEE_TEAM}`, { data });
      }
      if (response?.success === true) {
        Toast(response?.message, "success");
        setTimeout(() => {
          setRemoveTeamModal(false);
          getTeamData()
        }, 2000)
      }
    } catch (error) {
      HandleErrorMessage(error);
    }
  }


  const handleSelectedRowsChange = (type) => ({ selectedRows }) => {
    if (selectedRows && selectedRows.length > 0) {
      const employees_id = selectedRows.map(item => item._id);
      if (type === 'add') {
        setSelectedEmplyees(employees_id);
      } else if (type === 'remove') {
        setSelectedEmplyeesRemove(employees_id);
      }
    }
  };



  const filteredItems = useMemo(
    () => filterItemsBySearchText(addemployeeData, searchText),
    [addemployeeData, searchText]
  );

  const filteredItemRemove = useMemo(
    () => filterItemsBySearchText(removeemployeeData, searchTextRemove),
    [removeemployeeData, searchTextRemove]
  );



  return (
    <>
      <ToastContainer />
      {((createPermission && loginType === "employee") ||
        loginType === "organization") && (
          <ActionButton
            label={"Add Team"}
            onClick={handleAddMember}
            color={"danger"}
          />
        )}
      <CommonModal
        isOpen={isAddModalOpen}
        toggle={Addtoggle}
        formik={formik}
        modalTitle={"Add Team"}
        buttonText={"Save"}
        className={"btn-success"}
      />
      <CommonModal
        isOpen={isModalOpen}
        toggle={toggle}
        formik={formik}
        modalTitle={"Team Update"}
        buttonText={"Update Team"}
        className={"btn-danger"}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTeamData()}
        onCloseClick={() => setDeleteModal(false)}
        Role="Team"
      />

      <MemoizedDialog
        isOpen={addTeamModal}
        toggle={() => setAddTeamModal(!addTeamModal)}
        headerTitle="Add Employee in Team"
        searchText={searchText}
        handleSearch={handleSearch(setSearchText)}
        columns={columns}
        data={filteredItems}
        onSelectedRowsChange={handleSelectedRowsChange('add')}
        footerButtonText="Add Employee Team"
        onFooterButtonClick={addEmployeesInTeams}
      />

      <MemoizedDialog
        isOpen={removeTeamModal}
        toggle={() => setRemoveTeamModal(!removeTeamModal)}
        headerTitle={removeTeamID?.teamName}
        searchText={searchTextRemove}
        handleSearch={handleSearch(setSearchTextRemove)}
        columns={column}
        data={filteredItemRemove}
        onSelectedRowsChange={handleSelectedRowsChange('remove')}
        footerButtonText="Remove Employee From Team"
        onFooterButtonClick={RemoveEmployeeTeam}
      />
    </>
  );
};


TeamMemberModal.propTypes = {
  teamMem: PropTypes.object,
  getTeamData: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  todo: PropTypes.object,
  isModalOpen: PropTypes.bool.isRequired,
  setDeleteModal: PropTypes.func.isRequired,
  deleteModal: PropTypes.bool.isRequired,
  updatedPermission: PropTypes.array.isRequired,
};

export default React.memo(TeamMemberModal);
