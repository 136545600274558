import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { GET_EMPLOYEE, POST_GET_INDUSTRY_TYPE, POST_PROFILE, SUBSCRIPTION_PLAN } from '../../helpers/url_helper';
import { APIClient } from '../../helpers/api_helper';
import { useLocation } from 'react-router-dom';
import { storageValues } from '../../Components/Common/DefaultDateSet';
import ModalManageUser from './ModalManageUser';
import { useProfileData } from '../../Components/Hooks/UserHooks';
import { toast, ToastContainer } from "react-toastify";
const api = new APIClient();

const SubscriptionPlan = () => {
    const { profileData } = useProfileData()
    const location = useLocation();
    const planType = location?.state?.data;
    const { type, pricePerGB, pricingPerUser } = planType || {};

    const [employeeSelect, setEmployeeSelecteded] = useState([]);
    // const [numchecked, setNumChecked] = useState(false)
    const [transactionType, setTransactionType] = useState([])
    //modal open
    const [addTeamModal, setAddTeamModal] = useState(false);
    const [numberEmployee, setNumberEmployee] = useState(null);
    const [storage, setStorage] = useState()
    const [adonData, setAdonData] = useState([])
    const [subPurchase, setSubPurchase] = useState([])
    const [perEmployee, setPerEmployee] = useState([])


    const userDiff = useMemo(() => {
        const profileValue = Number(profileData?.organizationStrength || 0);
        const employeeLength = Array.isArray(perEmployee) ? perEmployee.length : 0;
        return profileValue - employeeLength;
    }, [profileData, perEmployee]);


    useEffect(() => {
        setNumberEmployee(userDiff);
    }, [userDiff]);



    const subscriptionPlan = useCallback(async (storages, numEmployees) => {
        let trplanType = '';
        if (numEmployees && storages) {
            trplanType = transactionType?.SUBSCRIPTION_BOTH;
        } else if (numEmployees) {
            trplanType = transactionType?.SUBSCRIPTION_EMPLOYEE;
        }
        masterData();
        if (numEmployees > 0) {
            try {
                const data = {
                    planType: type,
                    employeeIds: employeeSelect,
                    numberOfEmployee: parseInt(numEmployees),
                    storage: storages,
                    transactionType: trplanType

                };
                const response = await api.create(`${SUBSCRIPTION_PLAN}`, data);
                if (response?.success === true) {
                    const res = response?.data;
                    setSubPurchase(res?.pGateWayResponse?.order?.url);
                    setAdonData(res?.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            setAdonData('');
        }
    }, [employeeSelect, transactionType, type]);




    const handleInputChange = (e) => {
        const numEmployees = e.target.value;
        if (!isNaN(Number(numEmployees)) && Number(numEmployees) >= 0) {
            if (Number(numEmployees) === 0) {
                setStorage('');
            }
            setNumberEmployee(numEmployees);
            subscriptionPlan(storage, numEmployees);
        }
    };

    const selectHandleChange = (e) => {
        const selectedStorage = e.target.value;
        if (!numberEmployee || numberEmployee === '0') {
            toast.error('Please enter users number', { autoClose: 2000 });
            return;
        }
        setStorage(selectedStorage);
        subscriptionPlan(selectedStorage, numberEmployee);
    };



    const createTeam = () => {
        setAddTeamModal(true)
    }


    useEffect(() => {
        masterData()
        getEmployeeData()
    }, [])


    const getEmployeeData = async () => {
        try {
            const response = await api.get(`${GET_EMPLOYEE}`);
            if (response.success === true) {
                setPerEmployee(response.data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }


    const masterData = async () => {
        try {
            const response = await api.get(`${POST_GET_INDUSTRY_TYPE}`);
            if (response.success === true) {
                const res = response?.data?.transactionTypeConstant;
                setTransactionType(res);
            }
        } catch (error) {
            console.error(error);
        }
    }


    const vatPer = adonData?.vatPercentage
    const totalUsersAmount = adonData?.organizationStrength && adonData?.paymentPerUser ? (adonData.organizationStrength * adonData.paymentPerUser) : 0;
    const totalStorageAmount = adonData?.totalStorage && pricePerGB ? (adonData.totalStorage * pricePerGB) : 0;

    const totalAmount = totalUsersAmount + totalStorageAmount;
    const igstAmount = (totalAmount * vatPer) / 100;
    const totalPayableAmount = totalAmount + igstAmount;

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="" pageTitle="Pages" />
                    <Row>
                        <Col xl={6}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardHeader>
                                            <span className="float-end text-secondary">₹ {pricingPerUser}/User</span>
                                            <div className="form-check mb-2">
                                                {/* <Input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="formCheck1"
                                                    onChange={handleCheckboxChange}
                                                    disabled={!numberEmployee}
                                                    checked={numchecked}
                                                /> */}

                                                <Label className="form-check-label fs-5" htmlFor="formCheck1">
                                                    Number of Users
                                                </Label>
                                            </div>
                                            <p className="text-muted mt-3">
                                                Empower your organization with seamless user management. Add users as needed to enhance your Call Management capabilities.
                                            </p>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xl={6}>
                                                    <div className="input-group">
                                                        <Label className="input-group-text">Number of Users</Label>
                                                        <Input type="number" className="form-control" placeholder=""
                                                            value={numberEmployee}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xl={6}>
                                                    <Button className="float-end rounded-pill" color='danger' onClick={() => createTeam()} >Manage Users</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {/* 
                            {numchecked && numberEmployee?.length > 0 && */}
                            <>
                                <Row className='mt-0'>
                                    <Col xl={12}>
                                        <Label className="form-check-label fs-5" htmlFor="formCheck1">
                                            Add-ons
                                        </Label>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xl={12}>
                                        <Card>
                                            <CardHeader>
                                                <span className="float-end text-secondary">{pricePerGB}/GB</span>
                                                <div className="form-check">
                                                    <Label className="form-check-label fs-5" htmlFor="formCheck2">
                                                        Call  Recording Storage
                                                    </Label>
                                                </div>
                                                <p className="text-muted mt-3">
                                                    Enhance your Call Management experience by adding storage capacity in GB for seamless call recording.
                                                </p>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col xl={6}>
                                                        <div className="input-group">
                                                            <Label className="input-group-text" htmlFor="storageSelect">Storage</Label>
                                                            <select className="form-select" id="storageSelect"
                                                                onChange={selectHandleChange} value={storage} >
                                                                <option value="">Select storage</option>
                                                                {storageValues().map((value, index) => (
                                                                    <option key={index} value={value}>
                                                                        {value} GB
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                            {/* } */}


                            <Col xl={6} className='mb-4'>
                                {/* <Button className='mt-1' type='submit' color='success' onClick={() => subscription(type)}>Buy Plan</Button> */}
                            </Col>
                        </Col>

                        <Col xxl={6}>
                            <Card className="card-height-100--">
                                <CardBody className="p-0">
                                    <div className="p-3 bg-soft-warning">
                                        <div className="float-end ms-2"></div>
                                        <h6 className="mb-0 text-danger">Subcription Summary</h6>
                                    </div>
                                    <div className="p-3">
                                        <div className="mt-3 pt-2">
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Number of Users</p>{adonData?.organizationStrength && adonData?.paymentPerUser ? `${adonData.organizationStrength} × ₹${adonData.paymentPerUser}` : '0'}
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{totalUsersAmount}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Call Recording Storage</p>{adonData?.totalStorage && pricePerGB ? `${adonData.totalStorage} GB × ₹${pricePerGB}` : '0'}
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{totalStorageAmount}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Total Taxable Amount</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{totalAmount}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">VAT {adonData?.vatPercentage}%</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{igstAmount || '0'}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Total Payable Amount</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{totalPayableAmount ||'0'}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3 pt-2">
                                            <a href={subPurchase} className="btn btn-danger w-100" target="_blank" rel="noreferrer">Continue</a>.
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <ModalManageUser setEmployeeSelecteded={setEmployeeSelecteded} addTeamModal={addTeamModal} setAddTeamModal={setAddTeamModal} />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SubscriptionPlan