import React from 'react'
import subExpired from '../../images/subExpired.png'
const SubExpired = ({ onClose }) => {
    return (
        <>
           <div className="subscription-expired-overlay">
            <div className='sn-expired-section'>
                <img src={subExpired} alt='date_image' />
                <h2>Your subscription has expired.
                </h2>
                <div className='sn-expierd-perragraph'>
                    <p className='mb-0'>You no longer have access to Sales Ninja.</p>
                    <p>To continue using Sales Ninja , you will need to upgrade your plan.</p>
                </div>
                <div className='sn-date-sub-btn-main mt-4'>
                    {/* <a href='/#' style={{ display:'inline-block',fontWeight:'500', border:'2px solid #fb6c6d',color:'#fb6c6d',width:'165px',borderRadius:'4px'}}>Continue</a> */}
                    <button style={{backgroundColor:'#fb6c6d',border:'2px solid #fb6c6d',color:'#fff',width:'150px',borderRadius:'4px',height:'45px',fontSize:'14px',fontWeight:'700'}} onClick={onClose}>Subscribe Now</button>
                </div>
            </div>
            </div>
        </>
    )
}

export default SubExpired




