import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import withRouter from "../../Components/Common/withRouter";
import { ToastContainer, toast } from "react-toastify";
import Toast from "../../Components/Common/Toast";
import { APIClient } from "../../helpers/api_helper";
import {
  EMPLOYEE_POST_FAKE_PASSWORD_FORGET,
  POST_FAKE_PASSWORD_FORGET,
} from "../../helpers/url_helper";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import Footer from "../../Layouts/Footer";
import logoheader from "../../assets/images/logo-main-white.png";
const api = new APIClient();


const ForgetPasswordPage = (props) => {
  const location = useLocation();
  const planType = location?.state;
  const [showWarning, setShowWarning] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async (values) => {
      try {
        const data = { email: values.email };
        let response;
        if (planType === "employee") {
          response = await api.create(
            `${EMPLOYEE_POST_FAKE_PASSWORD_FORGET}`,
            data
          );
        } else if (planType === "organization" || planType === null) {
          response = await api.create(`${POST_FAKE_PASSWORD_FORGET}`, data);
        }
        if (response.success === true) {
          setShowWarning(false);
          Toast(response.message, "success");
          setTimeout(() => {
            navigate("/");
            navigate("/");
          }, [2000]);
          validation.resetForm();
          setSuccessMessage(response.message);
        } else {
          setShowWarning(true);
        }
      } catch (error) {
        HandleErrorMessage(error);
        setShowWarning(true);
      }
    },
  });

  return (
    <ParticlesAuth>
      <ToastContainer />
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div className="sn-login-header-logo">
                  <div className="sn-admin-login-logo">
                    <img src={logoheader} alt="Logo" />
                  </div>
                </div>
              </div>
              <div className="text-center mt-sm-5 mb-4 text-white-50"></div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4" style={{ height: "300px" }}>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Forgot Password</h5>
                  </div>
                  {showWarning && (
                    <Alert
                      className="alert-borderless alert-warning text-center mt-4"
                      role="alert"
                    >
                      Enter your email and instructions will be sent to you!
                    </Alert>
                  )}
                  <div className="p-2 mt-2">
                    {/* {responseSuccess && (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {successMessage}
                    </Alert>
                  )} */}
                    {/* {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null} */}
                    {/* {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null} */}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">
                          Email<span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Send Reset Link
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...{" "}
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    {" "}
                    Click here{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="sn-footer-botom-page">
        <Footer />
      </div>
    </ParticlesAuth>
  );
};
ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};
export default withRouter(ForgetPasswordPage);
