import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input, FormFeedback } from 'reactstrap';

const CommonInput = ({ label, name, type, placeholder, formik }) => {
    return (
        <React.Fragment>
            <Label htmlFor={name} className="form-label">{label}</Label>
            <Input
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[name]}
                onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove non-letter and non-space characters
                }}
                invalid={formik.touched[name] && formik.errors[name]}
            />
            {formik.touched[name] && formik.errors[name] && (
                <FormFeedback type="invalid"><div>{formik.errors[name]}</div></FormFeedback>
            )}
        </React.Fragment>
    );
};

CommonInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired,
};

export default CommonInput;
