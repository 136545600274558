import {GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE, EDIT_PROFILE, PROFILE_SUCCESS, PROFILE_ERROR } from "./actionTypes";

const initialState = {
  loading: false,
  profileData: null,
  error: null,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
    case EDIT_PROFILE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PROFILE_SUCCESS:
    case PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: action.payload,
      };
    case GET_PROFILE_FAILURE:
    case PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default profile;

