import React, { useEffect, useState } from "react";
import { Card, Col, Modal, ModalBody, ModalHeader, Row, Button, ModalFooter, Spinner } from 'reactstrap';
import { GET_ROLE_MANAGEMENT, UPDATE_EMPLOYEE } from "../../../helpers/url_helper";
import DataTable from "react-data-table-component";
import { APIClient } from "../../../helpers/api_helper";
import Toast from "../../../Components/Common/Toast";
import { ToastContainer } from "react-toastify";
import { HandleErrorMessage } from "../../../Components/Common/HandleErrorMessage";

const api = new APIClient();

const tableCustomStyles = {
    headCells: {
        style: {
            width: '130px',
            justifyContent: 'center',
            fontWeight: '700',
            fontSize: 'small',
            fontFamily: 'Arial, Helvetica, sans-serif'
        },
    },
    cells: {
        style: {
            fontWeight: '500',
            width: '150px',
            fontSize: 'small',
            justifyContent: 'center',
            fontFamily: 'Arial, Helvetica, sans-serif'
        },
    },
    headRow: {
        style: {
            borderTop: '1px solid #ddd',
            background: '#fff2f2',
            fontWeight: '600',
            borderRight: '1px solid #ddd'
        },
    },
    headCells: {
        style: {
            borderRight: '1px solid #fff2f2',
            borderLeft: '1px solid #ddd',
            borderBottom: '1px solid #fff2f2',
        },
    },
    cells: {
        style: {
            borderRight: '1px solid #fff',
            borderLeft: '1px solid #ddd',
            '&:first-child': {
                borderLeft: '1px solid #ddd',
            },
            '&:last-child': {
                borderRight: '1px solid #ddd',
            },
        },
    },
};

const RoleAssign = ({ GetCreateData, assignRole, createAssignmodal_grid, setCreateAssignmodal_grid }) => {
    document.title = "User Management";
    const loginType = JSON.parse(localStorage.getItem("logintype"))
    const { id, assignRoleName } = assignRole;
    const [getRoleData, setGetRoleData] = useState([]);
    const [loading, setLoading] = useState(false);

    const toggleModal = () => {
        setCreateAssignmodal_grid(!createAssignmodal_grid);
    };

    useEffect(() => {
        if (assignRoleName) {
            getRoleManagementData();
        }
    }, [assignRoleName]);

    const getRoleManagementData = async () => {
        try {
            if (loginType === "organization") {
                const response = await api.get(GET_ROLE_MANAGEMENT);
                if (response.success) {
                    const apiResponse = response.data.map((item, index) => ({
                        id: index + 1,
                        name: item.name,
                        isSelected: assignRoleName.role.includes(item.name)
                    }));
                    setGetRoleData(apiResponse);
                }
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const handleCheckboxChange = (role) => {
        setGetRoleData(prevState => prevState.map(item =>
            item.id === role.id ? { ...item, isSelected: !item.isSelected } : item
        ));
    };

    const addRoleClick = async () => {
        setLoading(true);
        try {
            const selectedRoles = getRoleData.filter(role => role.isSelected).map(role => role.name);
            const data = {
                _id: id,
                role: selectedRoles
            };

            const response = await api.patch(UPDATE_EMPLOYEE, data);
            if (response.success) {
                Toast(response.message, "success");
                setCreateAssignmodal_grid(false);
                GetCreateData();
            }
        } catch (error) {
            HandleErrorMessage(error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            name: 'Select',
            cell: row => (
                <input
                    type="checkbox"
                    checked={row.isSelected}
                    onChange={() => handleCheckboxChange(row)}
                />
            ),
        },
        {
            name: <span className='font-weight-bold fs-13'>Role Name</span>,
            selector: row => row.name,
            sortable: true
        }

    ];

    return (
        <>
            <ToastContainer />
            <Modal
                size="xl"
                isOpen={createAssignmodal_grid}
                toggle={toggleModal}
                scrollable={true}
                id="exampleModalScrollable">
                <ModalHeader className="bg-light p-3" id="exampleModalScrollableTitle" toggle={toggleModal}>
                    Assign Role
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <DataTable
                                    columns={columns}
                                    customStyles={tableCustomStyles}
                                    data={getRoleData}
                                    selectableRows={false}
                                />
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="hstack gap-2 justify-content-end">
                        <Button disabled={loading} className="btn btn-success w-100" type="button" onClick={addRoleClick}>
                            {loading ? <Spinner size="sm" className='me-2'>Loading...</Spinner> : 'Save'}
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default RoleAssign;
