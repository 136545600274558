import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getIndustryType } from "../../helpers/fakebackend_helper";
import { industryTypeApiResponseError, industryTypeApiResponseSuccess } from "./action";
import { GET_INDUSTRY_TYPE } from "./actionType";


function* getIndustryTypeData() {
    try {
        const response = yield call(getIndustryType)
        yield put(industryTypeApiResponseSuccess(GET_INDUSTRY_TYPE, response?.data))
    } catch (error) {
        yield put(industryTypeApiResponseError(GET_INDUSTRY_TYPE, error))
    }
}

export function* watchIndustry() {
    yield takeEvery(GET_INDUSTRY_TYPE, getIndustryTypeData)
}

function* authIndustrySaga() {
    yield all([
        fork(watchIndustry),
    ])
}

export default authIndustrySaga;