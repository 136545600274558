import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_INDUSTRY_TYPE } from "./actionType"

export const industryTypeApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data }
})

export const industryTypeApiResponseError = (actionType, data) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, data }
})

export const getCallIndustryType = () => ({
    type: GET_INDUSTRY_TYPE,
})
