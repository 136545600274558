import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import TeamMemberModal from "./TeamMemberModal";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  EMPLOYEE_GET_TEAM,
  EMPLOYEE_GET_TEAM_EMPLOYEE_EXCLUDE,
  EMPLOYEE_GET_TEAM_EMPLOYEE_INCLUDE,
  GET_TEAM,
  GET_TEAM_EMPLOYEE_EXCLUDE,
  GET_TEAM_EMPLOYEE_INCLUDE,
} from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
import CommanDataTable from "../../Components/Common/DataTable";
import { sortMemberCount, toCamelCase } from "../../Components/Common/ToCamelCase";

const api = new APIClient();


const Team = () => {
  const [isLoading, setIsLoading] = useState(true);
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const loginType = JSON.parse(localStorage.getItem("logintype"));
  const permission = permissionType?.includes("GET_TEAMS");
  const deletePermission = permissionType?.includes("DELETE_TEAM");
  const updatedPermission = permissionType?.includes("UPDATE_TEAM_NAME");
  const AddToTeamPermission = permissionType?.includes("GET_EMPLOYEES");
  const AddTeamPermision = permissionType?.includes("ADD_TO_TEAM");
  const RemoveFromTeamPermission = permissionType?.includes("REMOVE_FROM_TEAM");
  const getTonRemoveFromTeam = permissionType?.includes("GET_TEAM_MEMBERS");

  //remove
  const [removeTeamID, setRemoveTeamID] = useState();
  const [removeTeamModal, setRemoveTeamModal] = useState(false);

  //add employee team
  const [getTeamID, setGetTeamID] = useState();
  const [addTeamModal, setAddTeamModal] = useState(false);

  // delete state
  const [todo, setTodo] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  //edit state
  const [teamMem, setTeamMem] = useState(null);
  //  open in modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  //ADD EMPLOYEE STATE
  const [addemployeeData, setAddEmployeeData] = useState([]);
  //REMOVEEMPLOYEE STATE
  const [removeemployeeData, setRemoveEmployeeData] = useState([]);

  //search field state
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  const columns = [
    {
      name: (
        <span
          className="font-weight-bold fs-13"
          style={{ textTransform: "capitalize" }}
        >
          Team Name
        </span>
      ),
      selector: (row) => toCamelCase(row.teamName) || "-",
      cell: (row) => (
        <span
          className="d-block mt-2 mb-2"
          style={{ textTransform: "capitalize" }}
        >
          {`${row.teamName || "-"}`}
        </span>
      ),
      sortable: true,
    },

    ...((getTonRemoveFromTeam && loginType === "employee") ||
      loginType === "organization"
      ? [
        {
          name: (
            <span className="font-weight-bold fs-13">
              Number Of Employees
            </span>
          ),
          selector: (row) => row?.memberCount || "-",
          cell: (row) => (
            <>
              <button
                type="button"
                className="btn btn-sm button-grey-main"
                onClick={() => removeEmployeeTeam(row)}
              >
                {row.memberCount}
              </button>
            </>
          ),
          sortable: true,
          sortFunction: sortMemberCount,
        },
      ]
      : []),

    ...(updatedPermission ||
      AddToTeamPermission ||
      (deletePermission && loginType === "employee") ||
      loginType === "organization"
      ? [
        {
          name: <span className="font-weight-bold fs-13">Action</span>,
          cell: (row) => (
            <UncontrolledDropdown className="dropdown d-inline-block">
              <DropdownToggle
                className="btn btn-soft-secondary btn-sm"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {((updatedPermission && loginType === "employee") ||
                  loginType === "organization") && (
                    <DropdownItem
                      className="edit-item-btn"
                      onClick={() => handleTeamClick(row)}
                    >
                      <Link className="link-success fs-15">
                        <i className="ri-pencil-fill"></i>
                      </Link>
                      Edit
                    </DropdownItem>
                  )}
                {((AddToTeamPermission && loginType === "employee") ||
                  loginType === "organization") && (
                    <DropdownItem onClick={() => createTeam(row)}>
                      <Link className="link-info fs-15">
                        <i className="mdi mdi-account-group"></i>
                      </Link>
                      &nbsp; Add Employee in Team
                    </DropdownItem>
                  )}
                <DropdownItem divider />
                {((deletePermission && loginType === "employee") ||
                  loginType === "organization") && (
                    <DropdownItem onClick={() => onClickTodoDelete(row)}>
                      <Link className="link-danger">
                        <i className="ri-delete-bin-5-line"></i>
                      </Link>{" "}
                      Delete
                    </DropdownItem>
                  )}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ]
      : []),
  ];


  // Debounce search query
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);


  // Fetch data when debounced query, currentPage, or perPage changes
  useEffect(() => {
    const fetchData = async () => {
      if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
        await getTeamData(currentPage, perPage, debouncedQuery);
      }
    };

    fetchData();
  }, [debouncedQuery, currentPage, perPage]);



  // Search field handler
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  // Pagination handler
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  // Fetch team data from API
  const getTeamData = async (page, size = perPage, searchTerm = '') => {
    setIsLoading(true);
    try {
      let response;
      if (loginType === 'employee' && permission) {
        response = await api.get(`${EMPLOYEE_GET_TEAM}?pg=${page}&lm=${size}&query=${searchTerm}`);
      } else if (loginType === 'organization') {
        response = await api.get(`${GET_TEAM}?pg=${page}&lm=${size}&query=${searchTerm}`);
      }

      if (response && response.success === true) {
        const apiResponse = response.data;
        console.log(apiResponse, 'apiResponse');
        const transformedData = apiResponse?.teams.map((item) => ({
          ...item,
          teamName: item.teamName,
        }));
        setTeamData(transformedData);
        setTotalRows(apiResponse.totalCount);
      } else {
        setTeamData([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.error('Error fetching team data:', error);
      setTeamData([]);
      setTotalRows(0);
    } finally {
      setIsLoading(false);
    }
  };



  //   REMOVE EMPLOYEE TEAM
  const removeEmployeeTeam = (row) => {
    setRemoveTeamID(row);
    setRemoveTeamModal(true);
    GetTeamEmployeeRemoveData([row?.teamName]);
  };

  // ADD EMPLOYEE TEAM
  const createTeam = (row) => {
    setGetTeamID(row?._id);
    setAddTeamModal(true);
    GetEmployeeCreateData([row?.teamName]);
  };

  // ADD_EMPLOYEE_GET_TEAMS_EXCLUDE_API
  const GetEmployeeCreateData = useCallback(
    async (teamName) => {
      try {
        let data = { teamName };
        let response;
        if (loginType === "employee" && AddToTeamPermission) {
          response = await api.create(
            `${EMPLOYEE_GET_TEAM_EMPLOYEE_EXCLUDE}`,
            data
          );
        } else if (loginType === "organization") {
          response = await api.create(`${GET_TEAM_EMPLOYEE_EXCLUDE}`, data);
        }
        if (response?.success === true) {
          const apiResponse = response?.data;
          const transformedData = apiResponse.map(item => ({
            ...item,
            fullName: item.fullName,
            team: item?.team.map((data) => data)
          }));
          setAddEmployeeData(transformedData);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [loginType, AddToTeamPermission]
  );



  // REMOVE_EMPLOYEE_GET_TEAMS_INCLUDE_API
  const GetTeamEmployeeRemoveData = useCallback(
    async (teamName) => {
      try {
        const data = { teamName };
        let response;
        if (loginType === "employee" && getTonRemoveFromTeam) {
          response = await api.create(
            `${EMPLOYEE_GET_TEAM_EMPLOYEE_INCLUDE}`,
            data
          );
        } else if (loginType === "organization") {
          response = await api.create(`${GET_TEAM_EMPLOYEE_INCLUDE}`, data);
        }
        if (response?.success === true) {
          const apiResponse = response?.data;
          console.log(apiResponse, "apiResponse");
          const transformedData = apiResponse.map(item => ({
            ...item,
            fullName: item.fullName,
            team: item?.team.map((data) => data)
          }));
          console.log(transformedData, "transformedData");
          setRemoveEmployeeData(transformedData);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [loginType, getTonRemoveFromTeam]
  );

  //edit team function
  const handleTeamClick = useCallback((arg) => {
    const teamMem = arg;
    setIsModalOpen(true);
    setTeamMem({
      id: teamMem._id,
      name: teamMem.teamName,
    });
  }, []);


  // delete function
  const onClickTodoDelete = (todo) => {
    setTodo(todo);
    setDeleteModal(true);
  };



  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content sn-team-managment-main-page">
        <Container fluid>
          <BreadCrumb title="TEAM MANAGEMENT" pageTitle="Tables" />
          <Row>
            <Col lg={12}>
              <Card className="h-100">
                <CardHeader>
                  <TeamMemberModal
                    updatedPermission={updatedPermission}
                    deletePermission={deletePermission}
                    loginType={loginType}
                    permissionType={permissionType}
                    todo={todo}
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                    teamMem={teamMem}
                    getTeamData={getTeamData}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    // add employee
                    addemployeeData={addemployeeData}
                    AddTeamPermision={AddTeamPermision}
                    getTeamID={getTeamID}
                    addTeamModal={addTeamModal}
                    setAddTeamModal={setAddTeamModal}
                    //remove employee
                    removeemployeeData={removeemployeeData}
                    RemoveFromTeamPermission={RemoveFromTeamPermission}
                    removeTeamID={removeTeamID}
                    setRemoveTeamModal={setRemoveTeamModal}
                    removeTeamModal={removeTeamModal}
                  />
                </CardHeader>
                <CardBody className="pt-0 sn-team-managment-tble">
                  <CommanDataTable
                    handleSearch={handleSearch}
                    searchText={query}
                    loginType={loginType}
                    permission={permission}
                    totalRows={totalRows}
                    isLoading={isLoading}
                    column={columns}
                    tableData={teamData}
                    handlePerRowsChange={handlePerRowsChange}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Team;
