import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects";
// Login Redux States
import { EDIT_PROFILE, GET_PROFILE_REQUEST } from "./actionTypes";
import {
  profileSuccess,
  profileError,
  getProfileSuccess,
  getProfileFailure,
} from "./actions";
import {
  getEmployeeProfiledata,
  getProfiledata,
  postFakeProfile,
  postJwtProfile,
} from "../../../helpers/fakebackend_helper";

function* fetchProfileData(action) {
  try {
    let response;
    if (action.storedValue === "employee") {
      response = yield call(getEmployeeProfiledata);
    } else if (action.storedValue === "organization") {
      response = yield call(getProfiledata);
    }
    if (response && response.success === true) {
      const { data } = response;
      yield put(getProfileSuccess(data));
    }
  } catch (error) {
    yield put(getProfileFailure(error.message));
  }
}

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(user.username, user.idx);
      yield put(profileSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      });
      yield put(profileSuccess(response));
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeProfile, user);
      yield put(profileSuccess(response));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

export function* watchFetchProfileData() {
  yield takeLatest(GET_PROFILE_REQUEST, fetchProfileData);
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

export default function* ProfileSaga() {
  yield all([fork(watchFetchProfileData), fork(watchProfile)]);
}
