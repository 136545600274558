import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { DashboardFillter } from "./DashboardSection";
import { useSelector } from "react-redux";
import { APIClient } from "../../helpers/api_helper";
import { DashboardCallSlider } from "../../Components/Common/Slider";
import { DashboardEmployeeSummary } from "../../Components/Common/Summary";
import tableimg from "../../../src/assets/images/perfarmanceTable.png";
import { ToastContainer } from "react-toastify";
import {
  DASHBOARD_GRAPH_DATA,
  DASHBOARD_PIE_CHART,
  EMPLOYEE_CALL_HOURS_GRAPH,
  EMPLOYEE_DATATABLE,
} from "../../helpers/url_helper";

import {
  MostActiveHourByCalls,
  OverallCallPieChart,
  OverallCallsVolume,
} from "../Charts/ChartsJs/ChartsJs";
import DashboardDataTable from "../../Components/Common/DashboardDataTbale";
import { formatDuration } from "../../Components/Common/convertTimestampToTime";
import { getInitialDates } from "../../Components/Common/DefaultDateSet";
import Loader from "../../Components/Common/Loader";
import Toast from "../../Components/Common/Toast";
const api = new APIClient();

const DashboardCallCrm = () => {
  const storedValue = JSON.parse(localStorage.getItem("logintype"));
  const permissionType = JSON.parse(localStorage.getItem("permission"));

  const { employee } = useSelector((state) => ({
    employee: state.EmployeeReducer?.employeeData,
  }));


  const { teamEmployee } = useSelector((state) => ({
    teamEmployee: state.TeamEmployeeReducer?.teamEmployeeData,
  }));

  const { employeeProfileData } = useSelector((state) => ({
    employeeProfileData: state.Profile?.profileData,
  }));

  // flatePicker Date
  const [selectedDates, setSelectedDates] = useState(getInitialDates());

  //state
  const [piechartData, setPieChartData] = useState([]);
  const [piechartsummaryData, setPieChartSummaryData] = useState([]);

  // Pagination settings
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeData, setEmployeeTableData] = useState([]);

  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
  const [EmployeeData, setEmployeeData] = useState([]);
  const [teamIndu, setTeamIndu] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphCallData, setGraphCallData] = useState([]);
  const [EmployeeDefault, setEmployeeDefault] = useState([]);

  const [datePicker, setDatePicker] = useState()
  //loading
  const [loading, setLoading] = useState(false);
  
  const [error, setError] = useState("");

  const columns = [
    {
      name: <span> SN.</span>,
      selector: (row, index) => (currentPage - 1) * perPage + index + 1,
      sortable: true,
      width: "55px", // This sets the width of the "Sr. No" column to 30px
    },

    {
      name: <span className="font-weight-bold fs-13">Agent Name</span>,
      selector: (row) => row?.employeeDesription?.fullName || "-",
      width: "180px",
      cell: (row) => (
        <div className="mt-2 mb-2">
          <h5
            style={{
              textTransform: "capitalize",
              paddingRight: "27px",
              fontSize: "small",
              fontWeight: "900",
              color: "#3d78e3",
            }}
          >
            {row?.employeeDesription?.fullName || "-"}
          </h5>
          {row?.employeeDesription?.team?.map((callDate, index) => (
            <React.Fragment key={index}>
              <span style={{ textTransform: "capitalize" }}>{callDate}</span>
              <br />
              {index !== row?.employeeDesription?.team?.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      ),
      sortable: true,
    },
    {
      name: <span>Unique Client</span>,
      selector: (row) => row.uniqueClient,
      sortable: true,

    },
    {
      name: <span>Total Duration</span>,
      selector: (row) => row.totalDuration,
      sortable: true,
    },
    {
      name: <span>Average Call Duration</span>,
      selector: (row) => row.avrerageCallDuration,
      sortable: true,
    },
    {
      name: <span>Total Call</span>,
      selector: (row) => row.totalCall || "-",
      cell: (row) => <span className="sn-total-callbg">{row.totalCall}</span>,
      sortable: true,
    },
    {
      name: <span>Incoming Call</span>,
      selector: (row) => row.totallIncomingCall || "-",
      cell: (row) => (
        <span className="sn-total-incoming-callbg">
          {row.totallIncomingCall}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span>Outgoing Call</span>,
      selector: (row) => row.totallOutgoingCall || "-",
      cell: (row) => (
        <span className="sn-total-outgoing-callbg">
          {row.totallOutgoingCall}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span>Missed Call </span>,
      selector: (row) => row.totallMissedCall || "-",
      cell: (row) => (
        <span className="sn-total-missed-callbg">{row.totallMissedCall}</span>
      ),
      sortable: true,
    },
    {
      name: <span>Rejected Call</span>,
      selector: (row) => row.totalRejectedCall || "-",
      cell: (row) => (
        <span className="sn-total-rejected-callbg">
          {row.totalRejectedCall}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span>Not Picked Up By Client</span>,
      selector: (row) => row.totalNotPickedUpByClient || "-",
      cell: (row) => (
        <span className="sn-total-not-picked-upby-clintng">
          {row.totalNotPickedUpByClient}
        </span>
      ),
      sortable: true,
    },
  ];


  const formattedDates = useMemo(
    () => ({
      startDate: selectedDates[0].format("YYYY-MM-DD"),
      endDate: selectedDates[1].format("YYYY-MM-DD"),
    }),
    [selectedDates]
  );


  useEffect(() => {
    if (EmployeeDefault.length > 0 || permissionType.length === 0) {
      const defaultId = EmployeeDefault.map((option) => option?.id) || [];
      fetchAllData(currentPage, perPage, defaultId, "");
    }
  }, [currentPage, perPage, EmployeeDefault, employeeProfileData]);


  useEffect(() => {
    let employeeOption = "";
    if (teamEmployee?.length > 0) {
      employeeOption = teamEmployee?.map((user) => ({
        id: `${user._id}`,
        value: user.fullName,
        label: `${user.fullName}`,
      }));
    } else {
      employeeOption = employee?.map((user) => ({
        id: `${user._id}`,
        value: user.fullName,
        label: `${user.fullName}`,
      }));
    }
    setEmployeeData(employeeOption);
  }, [employee, teamEmployee]);

  console.log(selectedEmployeeOptions, "selectedEmployeeOptions");

  const onShowData = () => {
    if (selectedEmployeeOptions.length > 0 || (permissionType.length === 0 && storedValue === 'employee')) {
      const updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
      fetchAllData(currentPage, perPage, "", updatedIds)
      setCurrentPage(1);
      setPerPage(10);
    }
  
  };



  const fetchAllData = useCallback(
    async (
      page = currentPage,
      size = perPage,
      defaultId = [],
      updatedIds = []) => {
      setLoading(true)
      try {
        const startDate = formattedDates?.startDate;
        const endDate = formattedDates?.endDate;
        let data = {
          startDate,
          endDate,
          employeeIds: updatedIds?.length > 0 ? updatedIds : defaultId || [],
        };

        // Combine all API calls in a Promise.all block
        const [graphResponse, chartsResponse, pieChartResponse, employeeResponse] = await Promise.all([
          api.create(`${EMPLOYEE_CALL_HOURS_GRAPH}`, data),
          api.create(`${DASHBOARD_GRAPH_DATA}`, data),
          api.create(`${DASHBOARD_PIE_CHART}`, data),
          api.create(`${EMPLOYEE_DATATABLE}?pg=${page}&lm=${size}`, data)
        ]);

        // Handle responses for each API call
        if (graphResponse?.success) {
          setDatePicker(formattedDates)
          setGraphData(graphResponse.data);
        }

        if (chartsResponse?.success) {
          setDatePicker(formattedDates)
          setGraphCallData(chartsResponse.data);
        }

        if (pieChartResponse?.success) {
          const result = pieChartResponse?.data?.data;
          setPieChartSummaryData(result);
          setPieChartData(pieChartResponse?.data);
        }

        if (employeeResponse?.success) {
          const apiResponse = employeeResponse.data;
          const tableData = (apiResponse.paginatedResults || []).map((data, index) => ({
            srNo: index + 1,
            employeeDesription: data.employeeDesription?.[0] || "-",
            totalCall: data.totalCall || "-",
            totalDuration: data.totalDuration
              ? new Date(data.totalDuration * 1000).toISOString().slice(11, 19)
              : "-",
            totallIncomingCall: data.totallIncomingCall || "-",
            totallIncomingConnectedCall: data.totallIncomingConnectedCall || "-",
            totallOutgoingCall: data.totallOutgoingCall || "-",
            totallOutgoingConnectedCall: data.totallOutgoingConnectedCall || "",
            totallMissedCall: data.totallMissedCall || "-",
            totalRejectedCall: data.totalRejectedCall || "-",
            totalNotPickedUpByClient: data.totalNotPickedUpByClient || "-",
            avrerageCallDuration: formatDuration(data.avrerageCallDuration) || "-",
            uniqueClient: data.uniqueClient || "-",
            connectedCalls: data.connectedCalls || "-",
            totallIncomingCallDuration: data.totallIncomingCallDuration
              ? new Date(data.totallIncomingCallDuration * 1000).toISOString().slice(11, 19)
              : "-",
            totallOutgoingCallDuration: data.totallOutgoingCallDuration
              ? new Date(data.totallOutgoingCallDuration * 1000).toISOString().slice(11, 19)
              : "",
          }));
          setEmployeeTableData(tableData);
          setTotalRows(apiResponse.totalCount?.[0]?.count || 0);
        }
      } catch (error) {
        console.error("Error in one or more API calls:", error);
      } finally {
        setLoading(false)
      }
    },
    [formattedDates, currentPage, perPage]
  );



  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);


  const handlePerPageChange = useCallback((newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  }, []);



  // resete select field in clear
  const handleClickClear = () => {
    setSelectedEmployeeOptions([]);
    setTeamIndu([]);
  };


  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <Col>
            <div className="h-100">
              {/* fillter section */}
              <DashboardFillter
              setError={setError}
              error={error}
                EmployeeDefault={EmployeeDefault}
                setEmployeeDefault={setEmployeeDefault}
                handleClickClear={handleClickClear}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                onShowData={onShowData}
                setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                teamIndu={teamIndu}
                setTeamIndu={setTeamIndu}
                arnOption={EmployeeData}
                selectedEmployeeOptions={selectedEmployeeOptions}
              />

              {/* OverAllCall PieChartsTodayCall ,LineCharts and employee summary  */}
              {loading && <div className="loader-overlay"><Loader /></div>}

              <div className={`content ${loading ? "blurred" : ""}`}>
                <Row>
                  <OverallCallPieChart
                    piechartsummaryData={piechartsummaryData}
                    dataColors='["#f17171","#eca8f7","#67b173","#80adfd","000"]'
                  />

                  <Col className="col-md-9 col-sm-9">
                    <DashboardEmployeeSummary
                      piechartData={piechartData}
                      EmployeeDefault={EmployeeDefault}
                    />

                    {/* OverAllCall Volume */}
                    <OverallCallsVolume
                      formattedDates={datePicker}
                      graphData={graphCallData}
                      callGraphData={graphData}
                      dataColors='["#c8e3cc", "#67b173","#c0d6fea1", "#80adfd","#ce8dc66e","#9c27b0","#f6d4fbad","#eca8f7","#f8b8b880","#f17171"]'
                    />
                  </Col>
                </Row>

                {/* Call Slider */}
                <DashboardCallSlider
                  piechartsummaryData={piechartsummaryData}
                  piechartData={piechartData}
                />

                {/* employee perfomance */}
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="salenninja-dashboard-agent-tblemani"
                  >
                    <div>
                      <Card className="h-100">
                        <CardHeader className="align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Agent Performance
                          </h4>
                        </CardHeader>

                        {employeeData?.length > 0 ? (
                          <CardBody>
                            <>
                              <DashboardDataTable
                                handlePerRowsChange={handlePerPageChange}
                                totalRows={totalRows}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                                column={columns}
                                fixedHeaderScrollHeights="480px"
                                tableData={employeeData}
                                perPage={perPage}
                              />
                            </>
                          </CardBody>
                        ) : (
                          <>
                            <div className="sn-dashboard-blur-img">
                              <img
                                src={tableimg}
                                alt=""
                                style={{ filter: "blur(2px)", width: "100%" }}
                              />
                            </div>
                          </>
                        )}
                      </Card>
                    </div>
                  </Col>
                </Row>
                {/* Most Active Hours Call */}
              </div>
              <MostActiveHourByCalls graphData={graphData} />
            </div>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardCallCrm;