import React, { useEffect } from "react";
import { useProfile } from "../Components/Hooks/UserHooks";
import { useNavigate } from "react-router-dom";

const AuthProtected = ({ children }) => {
  const navigate = useNavigate();
  const { token } = useProfile();

  useEffect(() => {
    if (!token) {
      navigate('/login', { state: { from: window.location.pathname }, replace: true });
    }
  }, [token, navigate]);

  if (!token) {
    return null;
  }
  return <>{children}</>;
};



export { AuthProtected };

