import React, { useEffect, useState } from 'react'
import { Label } from 'reactstrap'
import ReactSelect from "react-select";
import { EMPLOYEE_GET_EMPLOYEE, EMPLOYEE_GET_TEAM_EMPLOYEE_INCLUDE, GET_EMPLOYEE, GET_TEAM_EMPLOYEE_INCLUDE } from '../../helpers/url_helper';
import { APIClient } from '../../helpers/api_helper';
const api = new APIClient();


const SelectOne = ({ setEmployeeData, EmployeeData, teamIndu, setSelectedEmployeeOptions, selectedEmployeeOptions, labelContent }) => {
    const storedValue = JSON.parse(localStorage.getItem("logintype"));
    const permissionType = JSON.parse(localStorage.getItem("permission"));
    const handleFetchEmployeeData = async () => {
        const teamType = teamIndu?.map(item => item?.value);
        if (teamType?.length > 0) {
            // Team is selected
            await getTeamWiseEmployeeData(teamType);
        } else {
            // No team is selected
            await fetchEmployeeData();
        }
    };

    useEffect(() => {
        fetchEmployeeData()
    }, [])

    const getTeamWiseEmployeeData = async (teamType) => {
        try {
            const data = { teamName: teamType || '' };
            let response;
            if (storedValue === 'employee' && data.teamName.length > 0) {
                response = await api.create(`${EMPLOYEE_GET_TEAM_EMPLOYEE_INCLUDE}`, data);
            } else if (storedValue === 'organization' && data.teamName.length > 0) {
                response = await api.create(`${GET_TEAM_EMPLOYEE_INCLUDE}`, data);
            }
            if (response?.success === true) {
                const result = response.data;
                const apiResponse = result.sort((a, b) => a.fullName.localeCompare(b.fullName));
                const arnOption = (apiResponse || []).map(employee => ({
                    id: `${employee?._id}`,
                    value: employee?.fullName,
                    label: `${employee?.fullName}`
                }));
                setEmployeeData(arnOption);
            } else {
                fetchEmployeeData();
            }
        } catch (error) {
            console.error("Error fetching call history:", error);
        }
    }


    const fetchEmployeeData = async () => {
        try {
            let response;
            if (storedValue === 'employee' && permissionType.length > 0) {
                response = await api.get(`${EMPLOYEE_GET_EMPLOYEE}`);
            }
            if (storedValue === "organization") {
                response = await api.get(`${GET_EMPLOYEE}`);
            }
            if (response?.success === true) {
                const apiResponse = response.data;
                const arnOption = apiResponse?.map(employee => ({
                    id: `${employee?._id}`,
                    value: employee?.fullName,
                    label: `${employee?.fullName}`
                }));
                setEmployeeData(arnOption);
            }

        } catch (error) {
            console.log("Error:", error);
        }
    }

    // employee select
    const handleEmployee = (selectedOption) => {
        if (selectedOption) {
            setSelectedEmployeeOptions(selectedOption)
        } else {
            setSelectedEmployeeOptions(null);
        }
    }


    useEffect(() => {
        if (EmployeeData.length > 0) {
            setSelectedEmployeeOptions(EmployeeData[0]);
        }
    }, [EmployeeData]);


    return (
        <>
            <Label for="exampleDate">{labelContent}</Label>
            <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                name="color"
                options={EmployeeData}
                value={selectedEmployeeOptions}
                onChange={(value) => handleEmployee(value)}
                onMenuOpen={() => {
                    handleFetchEmployeeData();
                }}
            />
        </>
    )
}

export default SelectOne