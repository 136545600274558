import React from 'react';
import { Col, Row } from 'reactstrap';

const BreadCrumb = ({ title, pageTitle }) => {
    return (
        <React.Fragment>
          
                <div className='sn-title-page-main-head'>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{title}</h4>
                        {/* <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="#">{pageTitle}</Link></li>
                                <li className="breadcrumb-item active">{""}</li>
                            </ol>
                        </div> */}
                    </div>
                </div>
          
        </React.Fragment>
    );
};

export default BreadCrumb;