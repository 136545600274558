import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { logoutUser } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import withRouter from "../../Components/Common/withRouter";
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logoutUser(navigate));
  }, [dispatch, navigate]);
  return null;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);