import React from 'react'
import linkedin from "../../assets/images/linkedin.png"
const Linkedin = () => {
  return (
    <>
       <img src={linkedin} alt="logo" />
    </>
  )
}

export default Linkedin
