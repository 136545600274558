import React from 'react'
import logotheme from '../../images/profile-bg.jpg'
const ThemeProfile = () => {
  return (
    <>
      <img src={logotheme} alt="logo" />
    </>
  )
}

export default ThemeProfile
