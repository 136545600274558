import React, { useEffect, useState } from "react";
import { CardBody, Row, Col, Card, Container, CardHeader } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import 'react-toastify/dist/ReactToastify.css';
import { APIClient } from "../../helpers/api_helper";
import { GET_INVOICE, GET_INVOICE_LIST } from "../../helpers/url_helper";
import CommanDataTable from "../../Components/Common/DataTable";
import moment from "moment";
const api = new APIClient();


const InvoiceList = () => {
  const [isLoading, setIsLoading] = useState(true);

  document.title = "Invoice List";
  const [loadingStates, setLoadingStates] = useState({});
  const [invoiceData, setInvoiceData] = useState([])
  const [searchText, setSearchText] = useState('');

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);


  const getInvoice = async (row) => {
    try {
      const response = await api.get(`${GET_INVOICE}/${row?.organizationId}/${row?.transactionId}`);
      return response?.message?.pdfUrl;
    } catch (error) {
      console.error('Error fetching invoice:', error);
    }
  };

  const handleButtonClick = async (row) => {
    setLoadingStates((prev) => ({ ...prev, [row.id]: true }));
    const pdfUrl = await getInvoice(row);
    setLoadingStates((prev) => ({ ...prev, [row.id]: false }));

    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = 'invoice.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };


  const columns = [
    {
      name: (
        <span
          className='font-weight-bold fs-13'
          style={{ width: "30px", display: "inline-block" }}
        >
          SN.
        </span>
      ),
      selector: row => row.srNo,
      sortable: true,
      width: "55px", 
    },
    {
      name: <span className='font-weight-bold fs-13'>Invoice Date</span>,
      selector: row => row.createdAt,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Amount</span>,
      selector: row => row.amount,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'> Paybale Amount</span>,
      selector: row => row.totalPaybaleAmount,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Payment Date</span>,
      selector: row => row.createdAt,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Storage Amount</span>,
      selector: row => row.storageAmount,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Total Storage</span>,
      selector: row => row.totalStorage,
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>Status</span>,
      sortable: true,
      selector: (cell) => {
        switch (cell.status) {
          case "success":
            return <span className="badge badge-soft-info" style={{ textTransform: 'capitalize' }}> {cell.status} </span>;
          case "pending":
            return <span className="badge badge-soft-secondary" style={{ textTransform: 'capitalize' }}>{cell.status}</span>;
          case "failure":
            return <span className="badge badge-soft-danger" style={{ textTransform: 'capitalize' }}> {cell.status}</span>;
          case "Inprogress":
            return <span className="badge badge-soft-warning" style={{ textTransform: 'capitalize' }}> {cell.status} </span>;
          case "Open":
            return <span className="badge badge-soft-primary" style={{ textTransform: 'capitalize' }}> {cell.status} </span>;
          case "New":
            return <span className="badge badge-soft-success" style={{ textTransform: 'capitalize' }}> {cell.status} </span>;
          default:
            return <span className="badge badge-soft-success" style={{ textTransform: 'capitalize' }}> {cell.status} </span>;
        }
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>Actions</span>,
      sortable: true,
      cell: (row) => {
        return (
          <>
            {row?.status === 'success' && (
              <div key={row.id}>
                <button
                  className="btn btn-sm button-grey-main edit-list"
                  onClick={(e) => {
                    e.preventDefault();
                    handleButtonClick(row);
                  }}
                >
                  {loadingStates[row.id] ? (
                    <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                  ) : (
                    <i className="ri-download-2-line" />
                  )}
                </button>
              </div>
            )
            }
          </>
        )
      },
    }
  ];

  const handlePageChange = page => {
    setCurrentPage(page);
    InvoiceData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    InvoiceData(page, newPerPage)
    setPerPage(newPerPage);
  }

  // search field 
  const handleSearch = (e) => {
    const value = e.target.value
    setSearchText(value);
    InvoiceData(1, perPage, value);
  };

  useEffect(() => {
    InvoiceData(currentPage, perPage)
  }, [currentPage, perPage])



  const InvoiceData = async (page, size = perPage) => {
    try {
      setIsLoading(true);
      const response = await api.get(`${GET_INVOICE_LIST}?pg=${page}&lm=${size}`)
      if (response?.success === true) {
        setIsLoading(false)
        const apiResponse = response?.data?.data
        const totalCount = response?.data.totalCount
        let tableData = (apiResponse || [])?.map((data, index) => {
          return {
            srNo: index + 1,
            id: data?._id,
            transactionId: data?.transactionId,
            name: data.name || "-",
            createdAt: moment(data?.createdAt).format('DD MMM YYYY') || '-',
            updatedAt: data?.updatedAt || '-',
            amount: data?.amount,
            totalPaybaleAmount: data?.totalPaybaleAmount,
            status: data?.status,
            storageAmount: data?.storageAmount,
            totalStorage: data?.totalStorage,
            organizationId: data?.organizationId
          }
        });
        setInvoiceData(tableData)
        setTotalRows(totalCount);
      }
    } catch (error) {
      console.log(error);
    }finally {
      setIsLoading(false);
  }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Invoice List" pageTitle="Invoices" />
          <Row>
            <Col lg={12}>
              <Card id="invoiceList">
                <CardHeader className="border-0">
                  <div className="d-flex align-items-center">
                    {invoiceData.length > 0 ? <h5 className="card-title mb-0 flex-grow-1 fs-17">{invoiceData.length} Invoice Found</h5> : ''}
                    <div className="flex-shrink-0">
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="pt-0 sn-invocie-table-datastatus">
                  <div>
                    <CommanDataTable
                      column={columns}
                      handleSearch={handleSearch}
                      searchText={searchText}
                      tableData={invoiceData}
                      handlePerRowsChange={handlePerRowsChange}
                      totalRows={totalRows}
                      currentPage={currentPage}
                      isLoading={isLoading}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceList;




