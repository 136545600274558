import React from "react";
import { Col, FormFeedback, Label, Modal, ModalBody, ModalHeader, Row, Form, Input, ModalFooter } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CREATE_ROLE_MANAGEMENT } from "../../../helpers/url_helper";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { APIClient } from '../../../helpers/api_helper';
import Toast from "../../../Components/Common/Toast";
import { ToastContainer } from "react-toastify";
import { HandleErrorMessage } from "../../../Components/Common/HandleErrorMessage";
const api = new APIClient();

const CreateRoleManagement = ({ getRoleManagementData, createmodal_grid, setCreatemodal_grid }) => {
  const createtog_grid = () => {
    setCreatemodal_grid(!createmodal_grid);
  };

  const formiks = useFormik({
    initialValues: {
      roleName: "",
    },
    validationSchema: Yup.object({
      roleName: Yup.string().required("Please Enter Your Role Name"),
    }),
    onSubmit: async (values) => {
      try {
        let data = {
          hierarchyName: values?.roleName,
        };
        const response = await api.create(`${CREATE_ROLE_MANAGEMENT}`, data);
        if (response.success && response.success === true) {
          Toast(response?.message, "success");
          setTimeout(() => {
            setCreatemodal_grid(false);
            getRoleManagementData();
            formiks.resetForm();
          }, 2000);
        } else if (response.success === false) {
          Toast(response?.message, "error");
        }
      } catch (error) {
        HandleErrorMessage(error);
      }
    },
  });


  return (
    <>
      <ToastContainer />
      <Row>
        <Modal
          isOpen={createmodal_grid}
          toggle={() => {
            createtog_grid();
          }}
        >
          <ModalHeader
            className="bg-light p-3"
            id="exampleModalLabel"
            toggle={() => {
              createtog_grid();
            }}
          >
            {" "}
            Add Role{" "}
          </ModalHeader>
          <Form
            onSubmit={formiks.handleSubmit}
            className="needs-validation"
            action="#"
          >
            <ModalBody>
              <Row>
                <Col sm={12}>
                  <Label htmlFor="roleName" className="form-label">
                    Role Name
                  </Label>
                  <Input
                    name="roleName"
                    type="text"
                    placeholder="Enter Role Name"
                    onChange={formiks.handleChange}
                    onBlur={formiks.handleBlur}
                    value={formiks.values.roleName}
                    invalid={
                      formiks.touched.roleName && formiks.errors.roleName
                        ? true
                        : false
                    }
                  />
                  {formiks.touched.roleName && formiks.errors.roleName ? (
                    <FormFeedback type="invalid">
                      <div>{formiks.errors.roleName}</div>
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button type="submit" className="btn btn-success" id="add-btn">
                  Add Role
                </button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </Row>
    </>
  );
};

export default CreateRoleManagement;
