// add action type
export const ADD_TEAM_MEMBER_REQUEST = 'ADD_TEAM_MEMBER_REQUEST';
export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS';
export const ADD_TEAM_MEMBER_FAILURE = 'ADD_TEAM_MEMBER_FAILURE';

// delete action type
export const DELETE_TEAM_DATA = "DELETE_TEAM_DATA";
export const DELETE_TEAM_DATA_SUCCESS = "DELETE_TEAM_DATA_SUCCESS";
export const DELETE_TEAM_DATA_FAIL = "DELETE_TEAM_DATA_FAIL";

// update action
export const UPDATE_TEAM_MEMBER_REQUEST = 'UPDATE_TEAM_MEMBER_REQUEST';
export const UPDATE_TEAM_MEMBER_SUCCESS = 'UPDATE_TEAM_MEMBER_SUCCESS';
export const UPDATE_TEAM_MEMBER_FAILURE = 'UPDATE_TEAM_MEMBER_FAILURE';

export const RESET_SUCCESS_STATE = 'RESET_SUCCESS_STATE'