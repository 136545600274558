import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    GET_TEAM_DATA,
    ADD_TEAM_DATA,
    UPDATE_TEAM_DATA,
} from "./actionType";
import {
    teamApiResponseSuccess,
    teamApiResponseError,
    addTeamDataSuccess,
    addTeamDataFail,
    updateTeamDataSuccess,
    updateTeamDataFail,
} from "./action";

import {
    getTeamData as getTeamDataApi,
    addTeamData,
    updateTeamData,
}
    from "../../helpers/fakebackend_helper";

function* getTeamData(action) {
    try {
        const { page, size } = action.payload;
        const response = yield call(getTeamDataApi, page, size);
        yield put(teamApiResponseSuccess(GET_TEAM_DATA, response));
    } catch (error) {
        yield put(teamApiResponseError(GET_TEAM_DATA, error));
    }
}

function* onAddTeamData({ payload: data }) {
    try {
        const response = yield call(addTeamData, data);
        yield put(addTeamDataSuccess(response));
        toast.success(response.message, { autoClose: 1000 });
    } catch (error) {
        yield put(addTeamDataFail(error));
        toast.error(error.response.data.message, { autoClose: 1000 });
    }
}

function* onUpdateTeamData({ payload: project }) {
    try {
        const response = yield call(updateTeamData, project);
        yield put(updateTeamDataSuccess(response));
        toast.success(response.message, { autoClose: 1000 });
    } catch (error) {
        yield put(updateTeamDataFail(error));
        toast.error(error.response.data.message, { autoClose: 1000 });
    }
}



export function* watchGetTeamData() {
    yield takeEvery(GET_TEAM_DATA, getTeamData);
}

export function* watchAddTeamData() {
    yield takeEvery(ADD_TEAM_DATA, onAddTeamData);

}

export function* watchUpdateTeamData() {
    yield takeEvery(UPDATE_TEAM_DATA, onUpdateTeamData);
}

function* teamSaga() {
    yield all([
        fork(watchGetTeamData),
        fork(watchAddTeamData),
        fork(watchUpdateTeamData),
    ]);
}

export default teamSaga;
