import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { FUTURE_SUBSCRIPTION, POST_GET_INDUSTRY_TYPE, POST_PROFILE } from '../../helpers/url_helper';
import { APIClient } from '../../helpers/api_helper';
import { formatDate } from '../../Components/Common/FormDateTime';
import moment from 'moment';
import { DatePickerFutureSub, isValidDate } from '../../Components/Common/Flatpicker';
import { storageValues } from '../../Components/Common/DefaultDateSet';
import { ToastContainer, toast } from 'react-toastify';
import ModalManageUser from './ModalManageUser';
const api = new APIClient();


const FuturePlan = () => {
    const [employeeSelect, setEmployeeSelecteded] = useState([]);
    const [planTypeData, setPlanTypeData] = useState([])
    const [transactionType, setTransactionType] = useState([])

    const [numchecked, setNumChecked] = useState(false)
    const [numberEmployee, setNumberEmployee] = useState('')
    //modal open
    const [addTeamModal, setAddTeamModal] = useState(false);
    const [adonData, setAdonData] = useState([])
    const [subPurchase, setSubPurchase] = useState([])

    // date picker state
    const [planExperiDate, setPlanExperiDate] = useState([])
    const [selectedFromDate, setSelectedFromDate] = useState([]);

    //futare Sub
    const [futStorage, setFutStorage] = useState()


    // // flatPicker Date
    const formattedStartDate = formatDate(selectedFromDate);

    const createTeam = () => {
        setAddTeamModal(true)
    }

    const handleToDateChange = (dateStr) => {
        setSelectedFromDate(dateStr);
    }


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.get(`${POST_PROFILE}`);
                if (response.success === true) {
                    const apiResponse = response?.data
                    setPlanTypeData(apiResponse?.selectedPlanDetails?.type)
                    setPlanExperiDate(apiResponse?.subscriptionEndDate)
                }
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchUserData()
        masterData()
    }, [])


    const masterData = async () => {
        try {
            const response = await api.get(`${POST_GET_INDUSTRY_TYPE}`);
            if (response.success === true) {
                const res = response?.data?.transactionTypeConstant;
                setTransactionType(res);
            }
        } catch (error) {
            console.error(error);
        }
    }


    const futureSubscription = async () => {
        if (!isValidDate(new Date(formattedStartDate))) {
            toast.error('Please select a start date.', { autoClose: 1000 });
            return;
        }
        let futplanType = '';
        if (numberEmployee && futStorage && formattedStartDate) {
            futplanType = transactionType?.FUTURE_SUBSCRIPTION_BOTH;
        } else if (numberEmployee && formattedStartDate) {
            futplanType = transactionType?.FUTURE_SUBSCRIPTION_EMPLOYEE;
        }
        masterData();
        let data = {
            planType: planTypeData,
            employeeIds: employeeSelect,
            numberOfEmployee: parseInt(numberEmployee),
            storage: parseInt(futStorage),
            transactionType: futplanType,
            startDate: formattedStartDate
        };
        try {
            const response = await api.create(`${FUTURE_SUBSCRIPTION}`, data);
            if (response?.success === true) {
                const res = response?.data;
                setSubPurchase(res?.url);
                setAdonData(res?.resData);
            }
        } catch (error) {
            console.error(error);
        }
    }


    const handleInputChange = (e) => {
        const value = e.target.value;
        const min = 1;
        // const max = 100; 
        // if (value === '' || (Number(value) >= min && Number(value) <= max)) {
        //     setNumberEmployee(value);
        // }
        if (value === '' || (Number(value) >= min)) {
            setNumberEmployee(value);
            setNumChecked(false);
        }
    };


    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setNumChecked(isChecked);
        if (!isChecked) {
            setNumberEmployee('');
        }
    };


    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Future Subscription" pageTitle="Pages" />
                    <Row>
                        <Col xl={6}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        {/* <CardHeader>
                                            <div className="form-check mb-0">
                                                <Label className="form-check-label fs-5" htmlFor="formCheck1">
                                                    Future Subscription
                                                </Label>
                                            </div>
                                        </CardHeader> */}
                                        <CardBody>

                                            <Row className="justify-content-end mb-3">
                                                <Col xs="auto">
                                                    <Button className="rounded btn-sm" color='danger' onClick={() => createTeam()} >
                                                        Manage Users
                                                    </Button>
                                                </Col>
                                            </Row>

                                            <Row className='d-flex'>
                                                <Col xs={12}>
                                                    <Alert color="danger" className="p-2 ml-0 rounded- alert-sm">
                                                        <p className="pt-1 d-flex ">
                                                            Your subscription is currently active and it will continue until {moment(planExperiDate).format('LL')}
                                                        </p>
                                                    </Alert>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={4}>
                                                    <DatePickerFutureSub incExperiDatePlan={planExperiDate} onChange={handleToDateChange} selectedFromDate={selectedFromDate} labelContent='Start Date' />
                                                </Col>

                                                <Col xl={4}>
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="formCheck1"
                                                        onChange={handleCheckboxChange}
                                                        disabled={!numberEmployee}
                                                        checked={numchecked}
                                                    />&nbsp;&nbsp;
                                                    <Label htmlFor="formCheck1">Number of Users</Label>
                                                    <Input type="number" className="form-control" placeholder="0"
                                                        value={numberEmployee}
                                                        onChange={handleInputChange}

                                                    />
                                                </Col>

                                                {numchecked && numberEmployee?.length > 0 &&
                                                    <Col xl={4}>
                                                        <Label for="exampleDate">Storage</Label>
                                                        <select className="form-select  form-control" onChange={(e) => setFutStorage(e.target.value)} >
                                                            <option >Select</option>
                                                            {storageValues().map((value, index) => (
                                                                <option key={index} value={value}>
                                                                    {value} GB
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Col>}
                                            </Row>

                                            <Button className='mt-4' type='submit' color='danger' onClick={futureSubscription}> Future Subscription</Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                        <Col xxl={6}>
                            <Card className="card-height-100">
                                <CardBody className="p-0">
                                    <div className="p-3 bg-soft-danger">
                                        <div className="float-end ms-2"></div>
                                        <h6 className="mb-0 text-danger">Subcription Summary</h6>
                                    </div>
                                    <div className="p-3">
                                        <div className="mt-3 pt-2">
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Number of Users</p>{adonData?.organizationStrength && adonData?.paymentPerUser ? `${adonData.organizationStrength} × ₹${adonData.paymentPerUser}` : '0'}
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{(adonData?.organizationStrength && adonData?.paymentPerUser) ? (adonData.organizationStrength * adonData.paymentPerUser) : '0'}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Call Recording Storage</p>{adonData?.totalStorage && adonData?.storagePerGb ? `${adonData.totalStorage} GB × ₹${adonData.storagePerGb}` : '0'}
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{adonData?.storageAmount !== null && adonData?.storageAmount !== undefined ? adonData.storageAmount : '0'}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Total Taxable Amount</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{adonData?.amount !== null && adonData?.amount !== undefined ? adonData.amount : '0'}</h6>
                                                </div>
                                            </div>

                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">IGST 18%</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{(adonData?.totalPaybaleAmount !== null && adonData?.totalPaybaleAmount !== undefined && adonData?.amount !== null && adonData?.amount !== undefined) ? (adonData.totalPaybaleAmount - adonData.amount) : '0'}</h6>
                                                </div>
                                            </div>

                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Total Payable Amount</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{adonData?.totalPaybaleAmount !== null && adonData?.totalPaybaleAmount !== undefined ? adonData.totalPaybaleAmount : '0'}</h6>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mt-3 pt-2">
                                            <a href={subPurchase} className="btn btn-danger w-100" target="_blank" rel="noreferrer">Continue</a>.
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <ModalManageUser setEmployeeSelecteded={setEmployeeSelecteded} addTeamModal={addTeamModal} setAddTeamModal={setAddTeamModal} />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default FuturePlan