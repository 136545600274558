import React, { useEffect } from 'react'
import { Label } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";
import { getCallIndustryType } from '../../store/CallType/action';


const EmployeeCallType = ({ selectedOptions, setSelectedOptions, labelContent }) => {
    const { industryTypes } = useSelector((state) => ({ industryTypes: state.IndustryTypeData?.industryData?.callTypes }));
    const dispatch = useDispatch();
    const callType = industryTypes?.map(type => ({value: type?.value,label: `${type?.value}`})) || [];

    return (
        <>
            <Label>
                {labelContent}
            </Label>
           <div >
           <MultiSelect
                options={callType}
                value={selectedOptions}
                onChange={setSelectedOptions}
                labelledBy={"Select"}
                overrideStrings={{ selectSomeItems: 'Select' }}
                isCreatable={true} 
                className='classgroup'
                onMenuToggle={(isOpen) => {
                    if (isOpen) {
                        dispatch(getCallIndustryType());
                    }
                }}
                />
           </div>
        </>
    )
}

export default EmployeeCallType





