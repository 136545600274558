
import { toast } from "react-toastify";
const Toast = (message, type) => {
  switch (type) {
    case "success":
      toast.success(message, { autoClose: 1000 });
      break;
    case "error":
      toast.error(message, { autoClose: 1000 });
      break;
    case "warning":
      toast.warning(message, { autoClose: 1000 });
      break;
    case "info":
      toast.info(message, { autoClose: 1000 });
      break;
    default:
      toast.warning("Toast not defined...");
  }
};

export default Toast;
