import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { getEmployeeResponseError, getEmployeeResponseSuccess } from "./action";
import { GET_EMPLOYEE_API_DATA } from "./actionType";
import { getEmployeedata } from "../../helpers/fakebackend_helper";


function* getEmploye() {
    try {
        const response = yield call(getEmployeedata);
        yield put(getEmployeeResponseSuccess(GET_EMPLOYEE_API_DATA, response?.data));
    } catch (error) {
        yield put(getEmployeeResponseError(GET_EMPLOYEE_API_DATA, error));
    }
}

export function* watchEmployee() {
    yield takeEvery(GET_EMPLOYEE_API_DATA, getEmploye)
}



function* authEmployeeSaga() {
    yield all([
        fork(watchEmployee),
    ]);
}

export default authEmployeeSaga;