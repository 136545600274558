import React, { useState } from 'react'
import { Col, Form, Input, Label, Row, TabPane } from 'reactstrap'
import { Link } from "react-router-dom";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EMPLOYEE_PATCH_CHANGE_PASSWORD, PATCH_CHANGE_PASSWORD } from '../../helpers/url_helper';
import { APIClient } from '../../helpers/api_helper';
import Toast from '../../Components/Common/Toast';
const api = new APIClient();

const ChangePassword = () => {
    const loginType = JSON.parse(localStorage.getItem("logintype"))
    const [oldpasswordShow, setOldPasswordShow] = useState(false);
    const [newpasswordShow, setNewPasswordShow] = useState(false);
    const [confirmpasswordShow, setConfirmPasswordShow] = useState(false);

    const { handleSubmit, errors, touched, getFieldProps, resetForm } = useFormik({
        enableReinitialize: true,
        initialValues: {
            oldPassword: '',
            newpassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Please Enter Current Password"),
            newpassword: Yup.string().min(8, 'Password must be 8 characters long')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[^\w]/, 'Password requires a symbol').required("Please Enter New Password"),
            confirmPassword: Yup.string().oneOf([Yup.ref('newpassword'), null], 'Password confirmation does not match').required("Please Enter Confirm Password"),
        }),
        onSubmit: async (values) => {
            let data = {
                oldPassword: values.oldPassword,
                newPassword: values.newpassword
            };
            // EMPLOYEE_PATCH_CHANGE_PASSWORD
            let response;
            if (loginType === 'employee') {
                response = await api.patch(`${EMPLOYEE_PATCH_CHANGE_PASSWORD}`, data);
            } else if (loginType === 'organization') {
                response = await api.patch(`${PATCH_CHANGE_PASSWORD}`, data);
            }
            Toast(response?.message, "success");
            if (response?.success === true) {
                setTimeout(() => {
                    resetForm();
                }, 2000)
            }
        }
    })

    return (
        <>
            <ToastContainer />
            <TabPane tabId="2">
                <Form onSubmit={handleSubmit}
                    action="#">
                    <Row className="g-2">
                        <Col lg={4}>
                            <div>
                                <Label htmlFor="oldpasswordInput" className="form-label">Current Password</Label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                    <Input
                                        name="oldPassword"
                                        type={oldpasswordShow ? "text" : "password"}
                                        placeholder="Enter current password"
                                        {...getFieldProps('oldPassword')}
                                        className={`${(touched.oldPassword && errors.oldPassword) && 'error_input'}`}
                                    />
                                    {(touched.oldPassword && errors.oldPassword) && <span className="text-danger error">{errors.oldPassword}</span>}
                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setOldPasswordShow(!oldpasswordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                </div>

                            </div>
                        </Col>

                        <Col lg={4}>
                            <div>
                                <Label htmlFor="newpasswordInput" className="form-label">New
                                    Password</Label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                    <Input
                                        name="newpassword"
                                        type={newpasswordShow ? "text" : "password"}
                                        placeholder="Enter new password"
                                        {...getFieldProps('newpassword')}
                                        className={`${(touched.newpassword && errors.newpassword) && 'error_input'}`}
                                    />
                                    {(touched.newpassword && errors.newpassword) && <span className="text-danger error">{errors.newpassword}</span>}
                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setNewPasswordShow(!newpasswordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                </div>

                            </div>
                        </Col>

                        <Col lg={4}>
                            <div>
                                <Label htmlFor="confirmpasswordInput" className="form-label">Confirm
                                    Password</Label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                    <Input
                                        name="confirmPassword"
                                        type={confirmpasswordShow ? "text" : "password"}
                                        placeholder="Enter confirm password"
                                        {...getFieldProps('confirmPassword')}
                                        className={`${(touched.confirmPassword && errors.confirmPassword) && 'error_input'}`}
                                    />
                                    {(touched.confirmPassword && errors.confirmPassword) && <span className="text-danger error">{errors.confirmPassword}</span>}
                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setConfirmPasswordShow(!confirmpasswordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                </div>

                            </div>
                        </Col>

                        {/* <Col lg={12}>
                            <div className="mb-3">
                                <Link to="#"
                                    className="link-primary text-decoration-underline">Forgot
                                    Password ?</Link>
                            </div>
                        </Col> */}

                        <Col lg={12} className='mt-4'>
                            <div className="text-end">
                                <button type="submit" className="btn btn-danger">Change
                                    Password </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                {/* <div className="mt-4 mb-3 border-bottom pb-2">
                    <div className="float-end">
                        <Link to="#" className="link-primary">All Logout</Link>
                    </div>
                    <h5 className="card-title">Login History</h5>
                </div>
                <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 avatar-sm">
                        <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                            <i className="ri-smartphone-line"></i>
                        </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <h6 className="fs-15">iPhone 12 Pro</h6>
                        <p className="text-muted mb-0">Los Angeles, United States - March 16 at
                            2:47PM</p>
                    </div>
                    <div>
                        <Link to="#">Logout</Link>
                    </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 avatar-sm">
                        <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                            <i className="ri-tablet-line"></i>
                        </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <h6 className="fs-15">Apple iPad Pro</h6>
                        <p className="text-muted mb-0">Washington, United States - November 06
                            at 10:43AM</p>
                    </div>
                    <div>
                        <Link to="#">Logout</Link>
                    </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                    <div className="flex-shrink-0 avatar-sm">
                        <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                            <i className="ri-smartphone-line"></i>
                        </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <h6 className="fs-15">Galaxy S21 Ultra 5G</h6>
                        <p className="text-muted mb-0">Conneticut, United States - June 12 at
                            3:24PM</p>
                    </div>
                    <div>
                        <Link to="#">Logout</Link>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 avatar-sm">
                        <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                            <i className="ri-macbook-line"></i>
                        </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <h6 className="fs-15">Dell Inspiron 14</h6>
                        <p className="text-muted mb-0">Phoenix, United States - July 26 at
                            8:10AM</p>
                    </div>
                    <div>
                        <Link to="#">Logout</Link>
                    </div>
                </div> */}
            </TabPane>
        </>
    )
}

export default ChangePassword