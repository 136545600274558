import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import { EMPLOYEE_GET_TEAM, GET_TEAM } from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
const api = new APIClient();

const TeamSelect = ({
  teamIndu,
  setTeamIndu,
  labelContent,
  setSelectedEmployeeOptions,
}) => {
  const storedValue = JSON.parse(localStorage.getItem("logintype"));
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const GetTeampermission = permissionType?.includes("GET_TEAMS");
  const [dataFetched, setDataFetched] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchTeamData = async () => {
    try {
      let response;
      if (storedValue === "employee" && permissionType.length > 0) {
        response = await api.get(`${EMPLOYEE_GET_TEAM}`);
      } else if (storedValue === "organization") {
        response = await api.get(`${GET_TEAM}`);
      }
      const fetchedOptions =
        response?.data?.teams?.map((item) => ({
          id: item?._id,
          label: item?.teamName,
          value: item?.teamName,
        })) || [];
      if (fetchedOptions.length > 0) {
        setOptions(fetchedOptions);
        setTeamIndu(fetchedOptions);
        setDataFetched(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error("Permission denied. Please check your permissions.");
        toast.error("Permission denied. Please check your permissions.", {
          autoClose: 1000,
        });
      } else {
        console.error("An error occurred:", error);
        toast.error("An unexpected error occurred. Please try again later.", {
          autoClose: 1000,
        });
      }
    }
  };

  useEffect(() => {
    if (!dataFetched) {
      fetchTeamData();
    }
  }, [dataFetched]);


  const handleSelectChange = (selectedOptions) => {
    setTeamIndu(selectedOptions);
    setSelectedEmployeeOptions([]);
  };

  return (
    <>
      <Label>{labelContent}</Label>
      <MultiSelect
        disabled={
          !GetTeampermission && storedValue === "employee"
            ? true
            : storedValue === "organization"
              ? false
              : false
        }
        options={options}
        value={teamIndu}
        onChange={handleSelectChange}
        labelledBy={"Select"}
        isCreatable={true}
        overrideStrings={{ selectSomeItems: "Select Teams" }}
        hasSelectAll={true}
        className="classgroup"
        onMenuToggle={(isOpen) => {
          if (isOpen && !dataFetched) {
            setSelectedEmployeeOptions([]);
            fetchTeamData();
          }
        }}
      />
    </>
  );
};

export default TeamSelect;
