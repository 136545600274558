import React from 'react'
import twitter from "../../assets/images/twitter.png"
const Twitter = () => {
  return (
    <>
       <img src={twitter} alt="logo" />
    </>
  )
}

export default Twitter
