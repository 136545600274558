export const formatToISOString = (date) => {
    return date ? date.toISOString() : '';
};

export const formatTime = (seconds) => {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = seconds % 60;

    return hours + "h " + minutes + "m " + remainingSeconds + "s";
}