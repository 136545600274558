import React from 'react';
import loaderimgmnew from '../../assets/images/loadersnc.gif'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Loader = (props) => {
    return (
        <React.Fragment>
           {/* <div className='sales-ninja-header-loader'> <div className='salen-ninja-main-loader-red' style={{ textAlign: 'center', margin: 'auto', position: 'relative', top: '50%', right: '0' }}> */}
           <div className='sales-ninja-header-loader sn-loader-main-table-icon'> <div className='salen-ninja-main-loader-red' style={{ textAlign: 'center', margin: 'auto', position: 'relative', right: '0' }}>
                <img src={loaderimgmnew} style={{ width: '150px', opacity:'0.8' }} />
            </div>
            {/* <div className="d-flex justify-content-center mx-2 mt-2">
                <Spinner color="primary"> Loading... </Spinner>
            </div> */}
            {toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}</div>
        </React.Fragment>
    );
};

export default Loader;
