//Crm_Api_Start

//REGISTER
export const POST_FAKE_REGISTER = "/organization/create-organization";
export const POST_REGISTER = "/organization/create-organization";

export const GET_STATES = "/public/get-states"
export const POST_CITIES = "/public/get-cities"
export const POST_PINCODE = "/public/get-pincode"

// Organization Login Method
export const POST_FAKE_LOGIN = "/organization/organization-login";

// Employee Login Method
export const POST_EMPLOYEE_FAKE_LOGIN = "/employee/login";

export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";


export const POST_FAKE_PASSWORD_FORGET = "/organization/forgot-password-link";
export const EMPLOYEE_POST_FAKE_PASSWORD_FORGET = "/employee/forgot-password-link";

export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";


export const SOCIAL_LOGIN = "/social-login";

//  EMPLOYEE LOGIN
export const POST_EMPLOYEE_LOGIN = "/employee/login";

//  CONTACT US
export const POST_CONTACT_US = "/admin/create-leads";
export const POST_GET_INDUSTRY_TYPE = "/organization/get-industry-type";

//PROFILE
export const POST_PROFILE = "/organization/get-organization-profile"
export const EMPLOYEE_PROFILE = "/employee/get-employee"

export const UPDATE_PROFILE = "/organization/update-organization"


export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

export const GET_EMPLOYEE_ID = "/organization/get-employee-by-id";
export const EMPLOYEE_GET_EMPLOYEE_ID = "/employee/get-employee-by-id";

// upload profile
export const PUT_UPLOAD_PROFILE = "/organization/upload-profile-image";
export const EMPLOYEE_PUT_UPLOAD_PROFILE = "/employee/profile-upload";


export const DELETE_PROFILE = "/organization/delete-profileimage"
export const EMPLOYEE_DELETE_PROFILE = "/employee/delete-profile-image"


// chnage password 
export const PATCH_CHANGE_PASSWORD = "/organization/change-password";
export const EMPLOYEE_PATCH_CHANGE_PASSWORD = "/employee/change-password";
export const PUCH_FORGET_PASSWORD = "/organization/change-password";


//forgot password
export const PUCH_NEW_PASSWORD = "/organization/forgot-password";
export const EMPLOYEE_PUCH_NEW_PASSWORD = "/employee/forgot-password";


//  CREATE EMPLOYEE 
export const CREATE_EMPLOYEE = "/organization/create-employee";
export const EMPLOYEE_CREATE_EMPLOYEE = "/employee/create-employee-permission";

// export const PLAN_DATA = "/payment/organization-plan?planType=paid-1&numberOfEmp=30";

export const GET_EMPLOYEES_LIST = "/organization/get-employees";
export const ENPLOYEE_GET_EMPLOYEE_LIST = "/employee/get-employees"

// get  Organization Employee

export const GET_EMPLOYEE = "/organization/get-employee-names";
export const EMPLOYEE_GET_EMPLOYEE = "/employee/get-employees-name";
//get Employee DASHBOARD

//updated organization
export const UPDATE_EMPLOYEE = "/organization/update-employee";
export const EMPLOYEE_UPDATE_EMPLOYEE = "/employee/update-permission-employee";

export const ACTIVE_INACTIVE_EMPLOYEE = "/organization/update-employee-status";
export const EMPLOYEE_ACTIVE_INACTIVE_EMPLOYEE = "/employee/update-employee-status";


// CREATE TEAM
export const CREATE_TEAM = "/organization/create-team";

//CREATE EMPLOYEE WISE
export const EMPLOYEE_CREATE_TEAM = "/employee/create-team";

//updated team 
export const UPDATE_TEAM = "/organization/update-team-name";
export const EMPLOYEE_UPDATE_TEAM = "/employee/update-team-name";

export const GET_TEAM = "/organization/get-teams";
export const EMPLOYEE_GET_TEAM = "/employee/get-teams";

//remove team
export const REMOVE_TEAM = "/organization/delete-team"
export const EMPLOYEE_REMOVE_TEAM = "/employee/delete-team"


export const GET_TEAM_EMPLOYEE_INCLUDE = "/organization/get-team-employee-include";
export const EMPLOYEE_GET_TEAM_EMPLOYEE_INCLUDE = "/employee/get-team-employee-include";



//GET TEAM EMPLOYEE
export const GET_TEAM_EMPLOYEE_EXCLUDE = "/organization/get-team-employee";
export const EMPLOYEE_GET_TEAM_EMPLOYEE_EXCLUDE = "/employee/get-team-employee";

//ADD EMPLOYEE AND ORGANIZATION TEAM
export const ADD_TO_EMPLOYEE_TEAM = "/organization/add-to-team"
export const EMPLOYEE_ADD_TO_EMPLOYEE_TEAM = "/employee/add-to-team"


//REMOVE EMPLOYEE AND ORGANIZATION TEAM
export const REMOVE_EMPLOYEE_TEAM = "/organization/remove-team";
export const EMPLOYEE_REMOVE_EMPLOYEE_TEAM = "/employee/remove-team"

// call History Report
export const CALL_REPORT = "/call-reports/get-calls";
//Employee Report
export const EMPLOYEE_REPORT = "/call-reports/employee-report";
//Client Report
export const CLIENT_REPORT = "/call-reports/get-client-report";

//Never Attended Report
export const NEVER_ATTENDED_REPORT = "/call-reports/get-never-attended";

//Not Pickuped Report
export const NOT_PICKED_REPORT = "/call-reports/get-not-picked-up-by-client";

// PLAN PRICING
export const PLAN_PRICING = "/organization/get-pricing-plan";

export const PAYMENT_INTEGRATION = "/payment/organization-plan";

export const SUBSCRIPTION_PLAN = "/payment/v2/subscription-plan"

export const ADDON_SUBSCRIPTIONPlAN = "/payment/v2/addon-subscription-plan";

export const FUTURE_SUBSCRIPTION = "/payment/v2/get-future-subscription";

//role management 
// add role 
export const CREATE_ROLE_MANAGEMENT = "/organization/create-hierarchy"
//delete role
export const DELETE_ROLE_MANAGEMENT = "/Organization/delete-hierarchy"
//get Role data
export const GET_ROLE_MANAGEMENT = "/organization/get-hierarchy"
// update api 
export const UPDATE_ROLE_MANAGEMENT = "/organization/update-hierarchy"


//employee dataTable 
export const EMPLOYEE_DATATABLE = "/call-reports/get-employees-performance"

//employee dataTable 
export const EMPLOYEE_CALL_HOURS_GRAPH = "/call-reports/get-call-hours"

//  graph Data 
export const DASHBOARD_GRAPH_DATA = "/call-reports/employee-report"

export const DASHBOARD_PIE_CHART = "/call-reports/get-dashboard-piechart"

//and dashboard api 

// Recording Data
export const GET_RECORDING_DATA = "/organization/get-call-recordings"
export const EMPLOYEE_GET_RECORDING_DATA = "/employee/get-call-recordings"

// Recording Data
export const GET_RECORDING_SUMMARY = "/organization/get-recording-summary"
export const EMPLOYEE_GET_RECORDING_SUMMARY = "/employee/get-recording-summary"

// delete Recording Data
export const DELETE_RECORDING = "/organization/delete-recording"

export const DELETE_ALL_RECORDING = "/organization/delete-all-recordings"

// dowalod archive
export const CREATE_ARCHIVES = "/organization/create-archive"

// get archive
export const GET_ARCHIVES = "/organization/get-archives-zip"

// delete archive
export const DELETE_ARCHIVES = "/organization/delete-archive-zip"

//permissions
export const GET_PERMISSIONS = "/public/get-permissions"
export const ADD_PERMISSION = "/organization/add-permission"
export const DELETE_PERMISSION = "/organization/delete-permission-hierarchy"

//Crm_Api_End

// invoice api 
export const GET_INVOICE_LIST = "/organization/get-transactions";
export const GET_INVOICE = "/public/get-invoice";
