import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_INDUSTRY_TYPE } from "./actionType";

const INIT_STATE = {
    industryData: [],
    error: {},
}

const IndustryTypeData = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_INDUSTRY_TYPE:
                    return {
                        ...state,
                        industryData: action?.payload?.data
                    }
                default:
                    return { ...state }
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_INDUSTRY_TYPE:
                    return {
                        ...state,
                        error: action.payload.error,
                    }
                default:
                    return { ...state }
            }
        case GET_INDUSTRY_TYPE:
            return {
                ...state,
            }

        default:
            return { ...state };
    }
}

export default IndustryTypeData