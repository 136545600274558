import { takeLatest, call, put, all, fork } from "redux-saga/effects";
import {
  ADD_TEAM_MEMBER_FAILURE,
  ADD_TEAM_MEMBER_REQUEST,
  ADD_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_DATA,
  DELETE_TEAM_DATA_FAIL,
  DELETE_TEAM_DATA_SUCCESS,
  UPDATE_TEAM_MEMBER_FAILURE,
  UPDATE_TEAM_MEMBER_REQUEST,
  UPDATE_TEAM_MEMBER_SUCCESS,
} from "./actionType";
import {
  EmployeedeleteTeamData,
  addTeamData,
  deleteTeamData,
  employeeUpdateTeamData,
  employeeaddTeamData,
  updateTeamData,
} from "../../helpers/fakebackend_helper";
import Toast from "../../Components/Common/Toast";

const loginType = JSON.parse(localStorage.getItem("logintype")) || "";
const permissionType = JSON.parse(localStorage.getItem("permission")) || [];
const deletePermission = permissionType?.includes("DELETE_TEAM");

// add team member
function* onAddTeamData(action) {
  const { member, logintype, permission } = action.payload;
  try {
    let response;
    if (logintype === "employee" && permission) {
      response = yield call(employeeaddTeamData, member);
    } else if (logintype === "organization") {
      response = yield call(addTeamData, member);
    }
    yield put({ type: ADD_TEAM_MEMBER_SUCCESS, payload: response });
    Toast(response.message, "success");
  } catch (error) {
    yield put({ type: ADD_TEAM_MEMBER_FAILURE, payload: error.message });
    Toast(error.response.data.message, "error");
  }
}

// updated team member
function* onUpdateTeamData(action) {
  const { teamName, teamId, logintype, updatedPermission } =
    action.payload.updatedTeam;
  const upresponse = { teamName: teamName, teamId: teamId };
  try {
    let response;
    if (logintype === "employee" && updatedPermission) {
      response = yield call(employeeUpdateTeamData, upresponse);
    } else if (logintype === "organization") {
      response = yield call(updateTeamData, upresponse);
    }
    yield put({ type: UPDATE_TEAM_MEMBER_SUCCESS, payload: response });
    Toast(response.message, "success");
  } catch (error) {
    yield put({ type: UPDATE_TEAM_MEMBER_FAILURE, payload: error.message });
    Toast(error.response.data.message, "error");
  }
}

// delete team member
function* onDeleteTeamData({ payload: data }) {
  try {
    let response;
    if (loginType === "employee" && deletePermission) {
      response = yield call(EmployeedeleteTeamData, { data });
    } else if (loginType === "organization") {
      response = yield call(deleteTeamData, { data });
    }
    yield put({ type: DELETE_TEAM_DATA_SUCCESS, payload: response });
    Toast(response.message, "success");
  } catch (error) {
    yield put({ type: DELETE_TEAM_DATA_FAIL, payload: error.message });
    Toast(error.response.data.message, "error");
  }
}

export function* watchAddteamSaga() {
  yield takeLatest(ADD_TEAM_MEMBER_REQUEST, onAddTeamData);
}

export function* watchUpdatedteamSaga() {
  yield takeLatest(UPDATE_TEAM_MEMBER_REQUEST, onUpdateTeamData);
}

export function* watchDeleteTeamData() {
  yield takeLatest(DELETE_TEAM_DATA, onDeleteTeamData);
}

function* teamSagas() {
  yield all([
    fork(watchAddteamSaga),
    fork(watchUpdatedteamSaga),
    fork(watchDeleteTeamData),
  ]);
}

export default teamSagas;
