import Toast from "./Toast";

export const HandleErrorMessage = (error) => {
    if (error.response) {
        const status = error.response.status;
        switch (status) {
            case 400:
                Toast(error.response.data.message, "error");
                break;
            case 404:
                Toast(error.response.data.message, "error");
                break;
            case status >= 500:
                Toast(error.response.data.message, "error");
                break;
            default:
                Toast(error.response.data.message, "error");
        }
    } else if (error.request) {
        Toast(error.message, "error");
    } else {
        Toast(error.message, "error");
    }
};


