import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    teamName: Yup.string()
        .matches(/^[A-Za-z\s]*$/, 'Only letters and spaces are allowed')
        .required('Please Enter Your Team Name')
        .max(30, 'Name must not be more than 16 characters'),
});

export const filterItemsBySearchText = (items, searchText) => {
    return items.filter(
      (item) =>
        item.fullName && item.fullName.toLowerCase().includes(searchText.toLowerCase())
    );
  };