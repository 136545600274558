import React from 'react'
import DataTable from 'react-data-table-component'
import { Col, Input, Row } from 'reactstrap'
import Loader from './Loader';

const tableCustomStyles = {
    headCells: {
        style: {
            width: '130px',
            justifyContent: 'center',
            // backgroundColor: '#dbdde0',
            fontWeight: '700',
            fontSize: 'small',
            fontFamily: 'Arial, Helvetica, sans-serif'
        },
    },
    cells: {
        style: {
            fontWeight: '500',
            width: '150px',
            fontSize: 'small',
            justifyContent: 'center',
            fontFamily: 'Arial, Helvetica, sans-serif'
        },
    },


    headRow: {
        style: {
            // borderTop: '1px solid #ddd',
            background: '#fff2f2',
            fontWeight: '600',
            // borderRight: '1px solid #ddd'

        },
    },
    headCells: {
        style: {
            borderRight: '1px solid #fff2f2',
            borderLeft: '1px solid #ddd',
            borderBottom: '1px solid #fff2f2',
        },
    },

    cells: {
        style: {
            borderRight: '1px solid #fff',
            borderLeft: '1px solid #ddd',
            '&:first-child': {
                borderLeft: '1px solid #ddd',
            },
            '&:last-child': {
                borderRight: '1px solid #ddd',
            },
        },
    },

};

const CommanDataTable = ({ handleSearch, isLoading, handleSort, loading, searchText, column, fixedHeaderScrollHeights,
    tableData, totalRows, currentPage, handlePageChange, handlePerRowsChange }) => {
    return (
        <>
            <div className='custom-datatable'>
                <Row className='g-3 mt-0'>
                    <Col xxl={2} md={4} className='ms-auto'>
                        <Input
                            value={searchText}
                            type="text"
                            className="form-control"
                            id="exampleInputrounded"
                            placeholder="Search by keyword..."
                            onChange={handleSearch}
                        />
                    </Col>
                    <Col lg={12} className='sn-tble-call-archive-mainn sn-team-number--employyes-btn sn-tble-bottom-scroll-main sn-total-calltype-btn-status sn-employee-managment-tble-detail' style={{ textAlign: 'center',overflow:'scroll'}}>
                        <DataTable
                            className='sn-managment-team-scroll-tble sn-tble-droup-down-edit-delete'
                            customStyles={tableCustomStyles}
                            defaultSortAsc={true}
                            columns={column}
                            data={tableData}
                            fixedHeaderScrollHeight={fixedHeaderScrollHeights ? fixedHeaderScrollHeights : ''}
                            fixedHeader
                            striped
                            search={true}
                            pagination={true}
                            paginationServer={true}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            noDataComponent={<div className="center-content">There are no records to display</div>}
                            responsive={true}
                            responsiveSm={true}
                            responsiveMd={true}
                            responsiveLg={true}
                            responsiveXl={true}
                            progressPending={isLoading}
                            progressComponent={<Loader />}
                        // onSort={handleSort}
                        // progressPending={loading}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default CommanDataTable