import { API_TEAM_RESPONSE_ERROR, API_TEAM_RESPONSE_SUCCESS, GET_TEAM_EMPLOYEE_API_DATA } from "./actionType"

export const getTeamEmployeeResponseSuccess = (actionType, data) => ({
    type: API_TEAM_RESPONSE_SUCCESS,
    payload: { actionType, data }
})

export const getTeamEmployeeResponseError = (actionType, error) => ({
    type: API_TEAM_RESPONSE_ERROR,
    payload: { actionType, error }
})

export const getTeamEmployeeIncludeApi = (employeeName) => ({
    type: GET_TEAM_EMPLOYEE_API_DATA,
    payload: employeeName
})