import React, { useState } from 'react'
import { Row, Col, Card, CardBody, Container, Input, Label, Form, Button } from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PUCH_NEW_PASSWORD } from '../../helpers/url_helper';
import { ToastContainer } from 'react-toastify';
import { APIClient } from '../../helpers/api_helper';
import Toast from '../../Components/Common/Toast';
const api = new APIClient();


const ForgetNewPassword = () => {
    const loginType = JSON.parse(localStorage.getItem("logintype"))
    const navigate = useNavigate()
    const location = useLocation();
    const path = location?.search.split("=")[1]?.split(".")[0]
    const [passwordShow, setPasswordShow] = useState(false);
    const [confipwordShow, setConfiPwordshow] = useState(false)

    const { handleSubmit, errors, touched, getFieldProps } = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().min(8, 'Password must be 8 characters long')
                .matches(/[0-9]/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[^\w]/, 'Password requires a symbol').required('Please enter password'),
            confirm_password: Yup
                .string()
                .required('Confirm password is required')
                .oneOf([Yup.ref('password')], 'Passwords do not match.Please check and try again.'),
        }),
        onSubmit: async (values) => {
            let data = {
                hash: path,
                newPassword: values?.password
            };
            try {
                const response = await api.patch(`${PUCH_NEW_PASSWORD}`, data);
                if (response?.success === true) {
                    Toast(response?.message, "success");
                    setTimeout(() => {
                        localStorage.removeItem('authUser');
                        navigate('/login')
                    }, [2000]);

                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    });


    return (
        <>
            <ParticlesAuth>
                <ToastContainer />
                <div className="auth-page-content">
                    <Container>
                        <Row className="justify-content-center mt-5">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Create New Password</h5>
                                        </div>
                                        <Form
                                            onSubmit={handleSubmit}
                                            className="needs-validation" >
                                            <div className="mb-3">
                                                <Label htmlFor="password" className="form-label">New Password<span className="text-danger">*</span></Label>
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        type={passwordShow ? "text" : "password"}
                                                        placeholder="Enter password"
                                                        {...getFieldProps('password')}
                                                        className={`${(touched.password && errors.password) && 'error_input'}`}
                                                    />
                                                    {(touched.password && errors.password) && <span className="text-danger error">{errors.password}</span>}
                                                    <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                        id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="confirm_password" className="form-label">Confirm Password<span className="text-danger">*</span></Label>
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        type={confipwordShow ? "text" : "password"}
                                                        placeholder="Enter confirm_password"
                                                        {...getFieldProps('confirm_password')}
                                                        className={`${(touched.confirm_password && errors.confirm_password) && 'error_input'}`}
                                                    />
                                                    {(touched.confirm_password && errors.confirm_password) && <span className="text-danger error">{errors.confirm_password}</span>}
                                                    <Button color="link" onClick={() => setConfiPwordshow(!confipwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                        id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                            </div>
                                            <div className="mt-4 text-end">
                                                <p className="mb-0"><Link to="/" className="fw-semibold text-primary text-decoration-underline">Back to Login</Link> </p>
                                            </div>
                                            <div className="text-center mt-4">
                                                <button className="btn btn-success w-100" type="submit">SUBMIT</button>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </>
    )
}

export default ForgetNewPassword
