import React from 'react'
import logo from '../../assets/images/salesNinja.png'
const NinjaLogo = () => {
    return (
        <>
            <img src={logo} alt="logo" />
        </>
    )
}

export default NinjaLogo
