import React from 'react'
import DataTable from 'react-data-table-component'
import { Col, Row } from 'reactstrap'


const tableCustomStyle = {
    table: {
        style: {
            height: '500px',
        },
    },
    headCells: {
        style: {
            width: '130px',
            justifyContent: 'center',
            // backgroundColor: '#dbdde0',
            fontWeight: '700',
            fontSize: 'small',
            fontFamily: 'Arial, Helvetica, sans-serif',
            background: 'red'
        },
    },
    cells: {
        style: {
            fontWeight: '500',
            width: '150px',
            fontSize: 'small',
            justifyContent: 'center',
            fontFamily: 'Arial, Helvetica, sans-serif'
        },
    },
    headRow: {
        style: {
            borderTop: '1px solid #ddd',
            background: '#fff2f2',
            fontWeight: '600',
            borderRight: '1px solid #ddd'

        },
    },
    headCells: {
        style: {
            borderRight: '1px solid #fff2f2',
            borderLeft: '1px solid #ddd',
            borderBottom: '1px solid #fff2f2',
        },
    },

    cells: {
        style: {
            borderRight: '1px solid #fff',
            borderLeft: '1px solid #ddd',
            '&:first-child': {
                borderLeft: '1px solid #ddd',
            },
            '&:last-child': {
                borderRight: '1px solid #ddd',
            },
        },
    },

}

const DashboardDataTable = ({ column, fixedHeaderScrollHeights,
    tableData, totalRows, currentPage, handlePageChange, handlePerRowsChange }) => {
    return (
        <>
            <Row className='n-tble-home-page'>
                <Col lg={12}>
                    <DataTable
                        customStyles={tableCustomStyle}
                        columns={column}
                        data={tableData}
                        fixedHeaderScrollHeight={fixedHeaderScrollHeights ? fixedHeaderScrollHeights : ''}
                        fixedHeader
                        striped
                        search={true}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationDefaultPage={currentPage}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        noDataComponent={<div className="center-content">There are no records to display</div>}
                        responsive={true}
                        responsiveSm={true}
                        responsiveMd={true}
                        responsiveLg={true}
                        responsiveXl={true}
                    />
                </Col>
            </Row>
        </>
    )
}

export default DashboardDataTable



