import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";
import { postFakeForgetPwd } from "../../../helpers/fakebackend_helper";


function* forgetUser({ payload: { user } }) {

  try {
    if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeForgetPwd, user);
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            response?.message
          )
        );
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}



function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;