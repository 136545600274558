import React from "react";
import { Col, Button, Row, Card, CardHeader, CardBody } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useSelector } from "react-redux";
import TeamSelect from "../../Components/Common/TeamSelect";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import SelectDatePicker from "./DashboardAllFillter/selectDatePicker";
import { useProfileData } from "../../Components/Hooks/UserHooks";


const DashboardFillter = ({
  EmployeeDefault,
  setEmployeeDefault,
  handleClickClear,
  onShowData,
  selectedDates,
  setSelectedDates,
  setSelectedEmployeeOptions,
  teamIndu,
  setTeamIndu,
  selectedEmployeeOptions,
  error,
  setError
}) => {
  const storedValue = JSON.parse(localStorage.getItem("logintype"));
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const { employeeProfileData } = useSelector((state) => ({
    employeeProfileData: state.Profile?.profileData,
  }));

  
  return (
    <React.Fragment>
      <Row>
        <BreadCrumb title="DASHBOARD" pageTitle="Dashboard" />
        <div className="mt-2 mt-lg-0 sn-top-inputs-heading sn-dashboard-page-home">
          <Row className="align-items-center">
            {/* {storedValue === 'employee' && permissionType.length === 0 ? <div className="col-md-3 col-sm-12">
              <div className="col-sm-auto">
                <div className="flex-grow-1 mb-4">
                  <h4
                    className="fs-16 mb-1"
                    style={{ textTransform: "capitalize" }}
                  >
                    Hello! {employeeProfileData?.fullName}  
                  </h4>
                </div>
              </div>
            </div> : ''} */}
            {storedValue === 'employee' && permissionType.length === 0 ? <>
              <div className="col-md-3 col-sm-12"><div className="col-sm-auto"></div></div>
            </> :
              <>
                <div className="col-md-3 col-sm-12">
                  <div className="col-sm-auto">
                    <TeamSelect
                      setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                      teamIndu={teamIndu}
                      setTeamIndu={setTeamIndu}
                      labelContent="Select Team"
                    />
                  </div>
                </div>
              </>
            }
            {storedValue === 'employee' && permissionType.length === 0 ? <>
              <div className="col-md-3 col-sm-12"><div className="col-sm-auto"></div></div>
            </> :
              <>
                <div className="col-md-3 col-sm-12">
                  <div className="col-sm-auto">
                    <EmployeeSelect
                    error={error}
                    setError={setError}
                      EmployeeDefault={EmployeeDefault}
                      setEmployeeDefault={setEmployeeDefault}
                      labelContent="Select Employee"
                      teamIndu={teamIndu}
                      selectedEmployeeOptions={selectedEmployeeOptions}
                      setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                    />
                  </div>
                </div>
              </>}
            <div className="col-md-6 col-sm-12 sn-dasboard-datepicker-btn-main">
              <div className="col-sm-auto d-flex">
                <SelectDatePicker
                  className="border"
                  selectedDates={selectedDates}
                  setSelectedDates={setSelectedDates}
                />
                <Button
                  type="button"
                  className="btn btn-soft-danger ms-2 d-flex"
                  onClick={onShowData}
                >
                  {" "}
                  Show
                </Button>
                <Button
                  type="button"
                  className="btn btn-soft-danger ms-2 d-flex sn-refresh-btn"
                  onClick={handleClickClear}
                >
                  <i className="ri-refresh-line align-bottom"></i>
                </Button>
              </div>
            </div>
          </Row>
        </div>
      </Row>
    </React.Fragment>
  );
};


export { DashboardFillter };
