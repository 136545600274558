import React from 'react'
import resetpass from '../../assets/images/resetpass.png'
const ResetPassword = () => {
  return (
    <>
      <img src={resetpass} alt="logo" />
    </>
  )
}

export default ResetPassword
