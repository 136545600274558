import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
//import images
import salesninjaicon from '../../assets/images/sales-ninja-icon.png'
import { getProfileRequest } from "../../store/actions";
const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const storedValue = JSON.parse(localStorage.getItem("logintype"));
  const { users } = useSelector((state) => ({
    users: state?.Profile?.profileData,
  }));
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  useEffect(() => {
    dispatch(getProfileRequest(storedValue));
  }, [dispatch, storedValue]);
  
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user header-profile-main-drow"
      >
        <DropdownToggle tag="button" type="button" className="btn sn-profile-droup-down-icon">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={users?.profileImageUrl ||salesninjaicon}
              height="32"
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2 sn-top-profile-top">
              <div>
                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text" style={{ textTransform: 'capitalize' }}>
                  {users?.organizationName ||users?.fullName}
                </span>
                <span className="d-none d-xl-block ms-1 fs-12 fw-bold text-muted user-name-sub-text">
                  <span>{users?.organizationId}</span>
                </span>
              </div>

            </span>

            <div className="sn-down-arrow-top">
              {/* <div className="sn-profile-down-arow"> <i className="ri-arrow-right-s-line"></i></div> */}
              <div className="sn-profile-down-hover"> <i className="ri-arrow-down-s-line"></i> </div>
            </div>

          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome <span style={{ textTransform: 'capitalize' }}>{users?.organizationName}</span></h6>
          <DropdownItem href={process.env.PUBLIC_URL + "/profile"}>
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          {/* <DropdownItem href={process.env.PUBLIC_URL + "/pages-faqs"}>
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Help</span>
          </DropdownItem> */}
          <div className="dropdown-divider"></div>
          {/* <DropdownItem
            href={process.env.PUBLIC_URL + "/pages-profile-settings"}
          >
            <span className="badge bg-soft-success text-success mt-1 float-end">
              New
            </span>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Settings</span>
          </DropdownItem> */}
          <DropdownItem href={process.env.PUBLIC_URL + "/logout"}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
