import React, { useEffect, useRef } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Mousewheel } from "swiper";

const DashboardCallSlider = ({ piechartsummaryData, piechartData }) => {
  const swiperRef = useRef(null);

  const handleMouseEnter = () => {
    if (swiperRef.current && swiperRef.current.autoplay) {
      swiperRef.current.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current && swiperRef.current.autoplay) {
      swiperRef.current.autoplay.start();
    }
  };


  let response = [];
  if (Array.isArray(piechartsummaryData)) {
    response = [...piechartsummaryData];
  }

  response.push(
    { _id: "TOTAL CALL", totalCalls: piechartData?.totalCall ?? 0 },
    { _id: "NOT PICKED CALL", totalCalls: piechartData?.notPickedUpByClient ?? 0 },
    { _id: "Total Connected Call", totalCalls: piechartData?.totalConnectedCalls ?? 0 },
    { _id: "Total Call Duration", totalCalls: piechartData?.totalCallDuration ?? 0 }
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Swiper
           onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
              slidesPerView={1}
              spaceBetween={24}
              mousewheel={false}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}

              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 3,
                },
                1600: {
                  slidesPerView: 5,
                },
              }}
              modules={[Autoplay, Mousewheel]}
              className="cryptoSlider"
            >
              {(response || []).map((item, key) => {
                let color;
                let cardClassName;
                let iconClassName = "";
                switch (item._id) {
                  case "INCOMING":
                    color = "#4CAF50";
                    iconClassName = "mdi mdi-phone-incoming";
                    cardClassName = "incoming-call";
                    break;
                  case "MISSED":
                    color = "#F44336";
                    iconClassName = "mdi mdi-phone-missed";
                    cardClassName = "missed-call";
                    break;
                  case "REJECTED":
                    color = "#9C27B0";
                    iconClassName = "mdi mdi-phone-cancel";
                    cardClassName = "avg-unique-call";
                    break;
                  case "OUTGOING":
                    color = "Blue";
                    iconClassName = "mdi mdi-phone-outgoing";
                    cardClassName = "number-of-employee";
                    break;
                  case "NOT PICKED CALL":
                    color = "#FF9800";
                    iconClassName = "mdi mdi-phone-remove";
                    cardClassName = "not-Picked-call";
                    break;
                  case "TOTAL CALL":
                    color = "#2196F3";
                    iconClassName = "mdi mdi-phone-in-talk";
                    cardClassName = "Total-Call-duration";
                    break;
                  default:
                    color = "#f17171";
                    iconClassName = "mdi mdi-phone-in-talk";
                    cardClassName = "avg-call-duration";
                    break;
                }

                return (
                  <SwiperSlide key={key}>
                    <Card className={cardClassName}>
                      <CardBody>
                        <div key={key} className="d-flex align-items-center">
                          <div className="avatar-sm flex-shrink-0">
                            <span className="fs-2" style={{ color }}>
                              <i className={iconClassName}></i>
                            </span>
                          </div>
                          <div className="flex-grow-1 ms-1">
                            <h6 className="mb-0">{item?._id || ""}</h6>
                            <h4 className="fs-14 mt-1 mb-0 fw-bold lh-2">
                              {item?.totalCalls}
                            </h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export { DashboardCallSlider };