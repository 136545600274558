import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS,GET_EMPLOYEE_API_DATA } from "./actionType";


export const getEmployeeResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data }
})

export const getEmployeeResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error }
})

export const getEmployeeApi = () => ({
    type: GET_EMPLOYEE_API_DATA
})



