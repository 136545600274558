import React from 'react'
import salesNinja from "../../assets/images/sales-ninja-email-logo.png"
const SalesNinjaLogo = () => {
  return (
    <>
       <img src={salesNinja} alt="logo" />
    </>
  )
}

export default SalesNinjaLogo
