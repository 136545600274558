import React from 'react';
import { Routes, Route } from "react-router-dom";
//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from './AuthProtected';
import NotFound from '../pages/AuthenticationInner/Errors/Basic404';
import Offlinepage from '../pages/AuthenticationInner/Errors/Offlinepage';


const Index = () => {
    return (
        <React.Fragment>
            <Offlinepage>
                <Routes>
                    <Route>
                        {publicRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={
                                    <NonAuthLayout>
                                        {route.component}
                                    </NonAuthLayout>
                                }
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Route>
                    <Route>
                        {authProtectedRoutes.map((route, idx) => (
                            <Route
                                path={route.path}
                                element={
                                    <AuthProtected>
                                        <VerticalLayout>{route.component}</VerticalLayout>
                                    </AuthProtected>}
                                key={idx}
                                exact={true}
                            />
                        ))}
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Offlinepage>
        </React.Fragment>
    );
};

export default Index;