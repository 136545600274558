import { useEffect, useState } from "react";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import { EMPLOYEE_PROFILE, POST_GET_INDUSTRY_TYPE, POST_PROFILE } from "../../helpers/url_helper";
import axios from "axios";
const api = new APIClient();


const useProfile = () => {
  const userProfileSession = getLoggedinUser();

  var token = userProfileSession ? userProfileSession : null;
  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession : null
  );

  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    var token = userProfileSession && userProfileSession["token"];
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(token ? false : true);
  }, []);

  return { userProfile, loading, token };
};

const useMasterApi = () => {
  const [masterApiData, setMasterApiData] = useState("")
  const accessToken = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    const GetMasteData = async () => {
      try {
        let config = {
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}${POST_GET_INDUSTRY_TYPE}`,
          headers: {
            'Authorization': `Bearer ${accessToken}`
          },
        };
        const response = await axios(config)
        const resultdata = response?.data;
        setMasterApiData(resultdata)
      } catch (error) {
        console.log(error);
      }
    };
    GetMasteData();
  }, [accessToken]);

  return { masterApiData };
}




const useProfileData = () => {
  const loginType = JSON.parse(localStorage.getItem("logintype"))
  const [profileData, setProfileData] = useState({});
  const [message, setMessage] = useState([])
  const [error, setError] = useState(null);

  useEffect(() => {
    const ProfileData = async () => {
      try {
        let response;
        if (loginType === "employee") {
          response = await api.get(`${EMPLOYEE_PROFILE}`);
        }
        if (loginType === "organization") {
          response = await api.get(`${POST_PROFILE}`);
        }
        if (response.data && response.data.status) {
          setProfileData(response.data);
          setMessage(response)
          setError(null);
        }
      } catch (error) {
        console.error(error);
        setError(error.response ? error.response.data : 'An error occurred');
      }
    };
    ProfileData();
  }, []);
  
  return { profileData, error, message };
}

export { useProfile, useMasterApi, useProfileData };