// ReusableModal.js
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Row, Col, Input, Card, Button } from 'reactstrap';
import DataTable from 'react-data-table-component';

// Define the reusable modal component
const Dialog = ({
    isOpen,
    toggle,
    headerTitle,
    searchText,
    handleSearch,
    columns,
    data,
    onSelectedRowsChange,
    footerButtonText,
    onFooterButtonClick
}) => {
    return (
        <Modal size="xl" isOpen={isOpen} toggle={toggle} scrollable={true} id="exampleModalScrollable">
            <ModalHeader className="bg-light p-3" toggle={toggle}>
                {headerTitle}
            </ModalHeader>
            <ModalBody>
                <Row className="sn-team-managment-tble">
                    <Col xxl={3} md={4} className="ms-auto">
                        <Input
                            type="text"
                            className="form-control"
                            placeholder="Search by keyword..."
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </Col>
                    <Col lg={12} className="mt-3">
                        <Card>
                            <DataTable
                                columns={columns}
                                data={data}
                                selectableRows
                                onSelectedRowsChange={onSelectedRowsChange}
                            />
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
            <div className="modal-footer">
                <Button type="button" color="danger" className="ms-1" onClick={onFooterButtonClick}>
                    {footerButtonText}
                </Button>
            </div>
        </Modal>
    );
};

// Add display name to the memoized component
const MemoizedDialog = React.memo(Dialog);
MemoizedDialog.displayName = 'Dialog';

// Define prop types for the component
Dialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    headerTitle: PropTypes.string.isRequired,
    searchText: PropTypes.string,
    handleSearch: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelectedRowsChange: PropTypes.func.isRequired,
    footerButtonText: PropTypes.string.isRequired,
    onFooterButtonClick: PropTypes.func.isRequired,
};

// Set default props
Dialog.defaultProps = {
    searchText: '',
};

export default MemoizedDialog;
