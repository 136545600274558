import React from "react";
import { Line } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import dummyImg from "../../../../src/assets/images/graphCall.png";
import graphChart from "../../../../src/assets/images/graphChart.png";
import donutImg from "../../../../src/assets/images/donutChart.png"


const OverallCallPieChart = ({ piechartsummaryData, dataColors }) => {
  var chartDonutupdatingColors = getChartColorsArray(dataColors);
  // const [loading, setLoading] = useState(true);
  let tableData = [];
  let labelData = [];

  // Populate tableData and labelData arrays
  for (let i = 0; i < piechartsummaryData?.length; i++) {
    tableData.push({
      value: piechartsummaryData[i].totalCalls,
      label: piechartsummaryData[i]._id,
    });
  }

  // Sort tableData based on the value
  tableData.sort((a, b) => a.value - b.value);

  // Separate the sorted data into series and labels
  const series = tableData.map(item => item.value);
  labelData = tableData.map(item => item.label);

  // Configuration for the chart
  const options = {
    labels: labelData,
    chart: {
      height: 280,
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
    },
    colors: chartDonutupdatingColors,
  };

  return (
    <React.Fragment>
      <div className="col-md-3 col-sm-12 col-xs-12">
        <div className="card card-height-100 ">
          {piechartsummaryData?.length > 0 ? (
            <CardBody>
              <>
                <div className="card-body">
                  <div
                    id="portfolio_donut_charts"
                    className="apex-charts"
                    dir="ltr"
                  >
                    <ReactApexChart
                      dir="ltr"
                      className="apex-charts"
                      series={series}
                      options={options}
                      type="donut"
                      height={267.7}
                    />

                  </div>
                  <ul className="list-group list-group-flush border-dashed mb-0 mt-3 pt-2">
                    {piechartsummaryData.sort((a, b) => a?._id.localeCompare(b?._id))?.map((item, index) => {
                      return (
                        <div key={index}>
                          <li className="list-group-item px-0">
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 ms-2">
                                <h6
                                  style={{
                                    paddingRight: "20px",
                                    fontSize: "11px",
                                  }}
                                  className="mb-0"
                                >
                                  {item._id}
                                </h6>
                              </div>
                              <div className="flex-shrink-0 text-end">
                                <h4
                                  className="mb-0 fw-bold"
                                  style={{
                                    paddingRight: "20px",
                                    fontSize: "11px",
                                  }}
                                >
                                  {item.totalCalls}
                                </h4>
                              </div>
                            </div>
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </>
            </CardBody>
          ) : (
            <>
              <div className="sn-dashboard-blur-img">
                <img src={donutImg} alt="" style={{ filter: "blur(2px)" }} className="img-filter-no-data" />
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};


const OverallCallsVolume = ({ dataColors, graphData, callGraphData, formattedDates }) => {
  var lineChartColor = getChartColorsArray(dataColors);
  const graphDate = graphData.map((item) => moment(item?._id?.date).format("MMM DD"))
  const strDate = formattedDates?.startDate
  const enndDate = formattedDates?.endDate
  const date1 = new Date(strDate);
  const date2 = new Date(enndDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  const service = {
    labels: diffDays > 0
      ? graphDate.sort((a, b) => new Date(a) - new Date(b)) 
      : callGraphData?.map((item) => item.workingTime),
    datasets: [
      {
        label: "Total Incoming Call",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[0],
        borderColor: lineChartColor[1],
        pointBorderColor: lineChartColor[1],
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[1],
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: diffDays > 0 ? graphData?.map((item) => item.totallIncomingCall) : callGraphData?.map((item) => item.totallIncomingCall),
      },
      {
        label: "Total Answered Call",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[4],
        borderColor: lineChartColor[5],
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineChartColor[5],
        pointBackgroundColor: "#ffcc00",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[5],
        pointHoverBorderColor: "#ffcc00",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: diffDays > 0 ? graphData?.map((item) => item.totallIncomingConnectedCall) : callGraphData?.map((item) => item.totallIncomingConnectedCall),
      },
      {
        label: "Total Outgoing Calls",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[2],
        borderColor: lineChartColor[3],
        pointBorderColor: lineChartColor[3],
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[3],
        pointHoverBorderColor: "#eef0f2",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: diffDays > 0 ? graphData?.map((item) => item.totallOutgoingConnectedCall) : callGraphData?.map((item) => item.totallOutgoingCall),
      },
      {
        label: "Total Rejected Call",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[6],
        borderColor: lineChartColor[7],
        pointBorderColor: lineChartColor[7],
        pointBackgroundColor: "f02c2c",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[7],
        pointHoverBorderColor: "f02c2c",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: diffDays > 0 ? graphData?.map((item) => item.totalRejectedCall) : callGraphData?.map((item) => item.totalRejectedCall),
      },
      {
        label: "Total Missed Call",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[8],
        borderColor: lineChartColor[9],
        pointBorderColor: lineChartColor[9],
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[9],
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: diffDays > 0 ? graphData?.map((item) => item.totallMissedCall) : callGraphData?.map((item) => item.totallMissedCall),
      },
    ],
  };



  const option = {
    scales: {
      x: {
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
  };



  return (
    <React.Fragment>
      <Row>
        <Col xl={12} className="sm sn-over-call-graph-main-home">
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Overall Calls Volume </h4>
            </CardHeader>
            {
              graphData?.length > 0 ? (
                <CardBody>
                  <Line width={723} height={200} data={service} options={option} />
                </CardBody>
              ) : (
                <div className="sn-dashboard-blur-img">
                    <img src={graphChart} alt="" style={{ filter: "blur(2px)", width: '100%' }} />
                </div>
              )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );

};


const MostActiveHourByCalls = ({ graphData }) => {

  const series = [
    {
      name: "Total Outgoing Call",
      data: graphData?.map((item) => item.totallOutgoingCall),
    },
    {
      name: "Total Incoming Call",
      data: graphData?.map((item) => item.totallIncomingCall),
    },
    {
      name: "Total Missed Call",
      data: graphData?.map((item) => item.totallMissedCall),
    },
    {
      name: "Total Rejected Call",
      data: graphData?.map((item) => item.totalRejectedCall),
    },
    {
      name: "Total Not Picked Call",
      data: graphData?.map((item) => item.totalNotPickedUpByClient),
    },
  ];

  const option = {
    chart: {
      type: "bar",
      stacked: true,
      height: 380,
    },

    plotOptions: {
      bar: {
        barHeight: "100%",
        horizontal: true,
        columnWidth: "45%",
      },
    },

    grid: {
      show: false,
    },

    dataLabels: {
      enabled: false,
    },
    // colors: ["#80adfd", "#9ce9a7", "#e7a6a6", "#eca8f7", "#f5d17f"],
    colors: ["#80adfd", "#67b173", "#f17171", "#eca8f7", "#f5d17f"],

    stroke: {
      width: 2,
      colors: ["#fb6c6d00"],
    },

    title: {
      text: "",
    },

    xaxis: {
      categories: graphData?.map((item) => item.workingTime),

      labels: {
        style: {
          color: "#fb6c6d",
          fontSize: "12px",
        },
        formatter: (val) => {
          if (val >= 100000) {
            return (val / 100000).toFixed(1) + "L";
          } else if (val >= 1000) {
            return (val / 1000).toFixed(1) + "K";
          } else {
            return val;
          }
        },
      },
    },

    tooltip: {
      // y: {
      //     formatter: function (val) {
      //         return val + "K"
      //     }
      // }
    },

    fill: {
      opacity: 1,
    },

    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 90,
    },
  };


  return (
    <React.Fragment>
      <Row>
        <Col className="col col-sm col-md col-lg col-xl col-xxl">
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Most Active Hour By Calls</h4>
            </CardHeader>
            {graphData?.length > 0 ? (
              <CardBody>
                <ReactApexChart
                  dir="ltr"
                  className="apex-charts"
                  options={option}
                  series={series}
                  type="bar"
                  height={400}
                />
              </CardBody>
            ) : (
              <div className="sn-dashboard-blur-img">
                <img src={dummyImg} alt="" style={{ filter: "blur(2px)", width: '100%' }} />
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export { OverallCallPieChart, OverallCallsVolume, MostActiveHourByCalls };

