import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { ADDON_SUBSCRIPTIONPlAN, GET_EMPLOYEE, POST_GET_INDUSTRY_TYPE, POST_PROFILE } from '../../helpers/url_helper';
import { APIClient } from '../../helpers/api_helper';
import { useLocation } from 'react-router-dom';
import { storageValues } from '../../Components/Common/DefaultDateSet';
import ModalManageUser from './ModalManageUser';
import { useProfileData } from '../../Components/Hooks/UserHooks';
const api = new APIClient();

const AddonPlan = () => {
    const { profileData } = useProfileData()
    const location = useLocation();
    const planType = location?.state?.data;
    const { pricePerGB, pricingPerUser } = planType
    // const [isUsersChecked, setIsUsersChecked] = useState(false);
    // const [isStorageChecked, setIsStorageChecked] = useState(false);
    // const [employeeSelect, setEmployeeSelecteded] = useState([]);
    const [planTypeData, setPlanTypeData] = useState([])
    const [transactionType, setTransactionType] = useState([])
    //modal open
    const [addTeamModal, setAddTeamModal] = useState(false);
    const [numberEmployee, setNumberEmployee] = useState()
    const [storage, setStorage] = useState()
    const [adonData, setAdonData] = useState([])
    const [subPurchase, setSubPurchase] = useState([])
    const [perEmployee, setPerEmployee] = useState([])


    const userDiff = useMemo(() => {
        const profileValue = Number(profileData?.organizationStrength || 0);
        // const employeeLength = Array.isArray(perEmployee) ? perEmployee.length : 0;
        // return profileValue - employeeLength;
        return profileValue;
    }, [profileData]);


    useEffect(() => {
        setNumberEmployee(userDiff);
    }, [userDiff]);

    const createTeam = () => {
        setAddTeamModal(true)
    }

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.get(`${POST_PROFILE}`);
                if (response.success === true) {
                    const apiResponse = response?.data
                    setPlanTypeData(apiResponse?.selectedPlanDetails?.type)
                }
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchUserData()
        masterData()
        getEmployeeData()
    }, [])


    const masterData = async () => {
        try {
            const response = await api.get(`${POST_GET_INDUSTRY_TYPE}`);
            if (response.success === true) {
                const res = response?.data?.transactionTypeConstant;
                setTransactionType(res);
            }
        } catch (error) {
            console.error(error);
        }
    }


    // Add-on subscription function
    const addonSubscriptionPlan = useCallback(async (numEmployees, storages) => {
        let planType = '';
        if (numEmployees && storages) {
            planType = transactionType?.ADD_ON_SUBSCRIPTION_BOTH;
        } else if (numEmployees) {
            planType = transactionType?.ADD_ON_SUBSCRIPTION_EMPLOYEE;
        } else if (storages) {
            planType = transactionType?.ADD_ON_SUBSCRIPTION_STORAGE;
        }
        masterData();
        let data = {
            planType: planTypeData,
            // employeeIds: employeeSelect,
            numberOfEmployee: parseInt(numEmployees),
            storage: storages,
            transactionType: planType
        };
        if (numEmployees > 0 || storages > 0) {
            try {
                const response = await api.create(`${ADDON_SUBSCRIPTIONPlAN}`, data);
                if (response?.success === true) {
                    const res = response?.data;
                    setSubPurchase(res?.pGateWayResponse?.order?.url);
                    setAdonData(res?.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            setAdonData('');
        }
    }, [transactionType, planTypeData]);



    // Handle input change for number of employees
    const handleNumberOfUser = (e) => {
        const numEmployees = e.target.value;
        if (!isNaN(Number(numEmployees)) && Number(numEmployees) >= 0) {
            // if (Number(numEmployees) === 0) {
            //     setStorage('');
            // }
            setNumberEmployee(numEmployees);
            addonSubscriptionPlan(numEmployees, storage);
        }

        // const numEmployees = e.target.value;
        // if (!isNaN(Number(numEmployees)) || numEmployees === '') {
        //     setNumberEmployee(numEmployees);
        //     addonSubscriptionPlan(numEmployees, storage);
        // }
    };


    const handleStorageChange = (e) => {
        const selectedStorage = e.target.value;
        setStorage(selectedStorage);
        addonSubscriptionPlan(numberEmployee, selectedStorage);
    };


    // const handleCheckboxChange = (type) => {
    //     if (type === 'users') {
    //         setIsUsersChecked(!isUsersChecked);
    //     } else if (type === 'storage') {
    //         setIsStorageChecked(!isStorageChecked);
    //     }
    // };


    const getEmployeeData = async () => {
        try {
            const response = await api.get(`${GET_EMPLOYEE}`);
            if (response.success === true) {
                setPerEmployee(response.data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const vatPer = adonData?.vatPercentage
    const totalUsersAmount = adonData?.organizationStrength && adonData?.paymentPerUser ? (adonData.organizationStrength * adonData.paymentPerUser) : 0;
    const totalStorageAmount = adonData?.totalStorage && pricePerGB ? (adonData.totalStorage * pricePerGB) : 0;

    const totalAmount = totalUsersAmount + totalStorageAmount;
    const igstAmount = (totalAmount * vatPer) / 100;
    const totalPayableAmount = totalAmount + igstAmount;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="add ons" pageTitle="Pages" />
                    <Row>
                        <Col xl={6}>

                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardHeader>
                                            <span className="float-end text-secondary">₹ {pricingPerUser}/User</span>
                                            <div className="form-check mb-2">
                                                {/* <Input
                                                    type="checkbox"
                                                    id="formCheck1"
                                                    checked={isUsersChecked}
                                                    onChange={() => handleCheckboxChange('users')}
                                                /> */}
                                                <Label className="form-check-label fs-5" htmlFor="formCheck1">
                                                    Number of Users
                                                </Label>
                                            </div>
                                            <p className="text-muted mt-3">
                                                Empower your organization with seamless user management. Add users as needed to enhance your Call Management capabilities.
                                            </p>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xl={6}>
                                                    {/* {isUsersChecked === true ? */}
                                                    <div className="input-group">
                                                        <Label className="input-group-text">Number of Users</Label>
                                                        <Input type="text" className="form-control"
                                                            placeholder="0" value={numberEmployee} onChange={handleNumberOfUser} />
                                                    </div>
                                                    <div className='mt-3'>
                                                        <p>Currently you have {perEmployee?.length} out of {profileData?.organizationStrength} employee(s).</p>
                                                    </div>
                                                    {/* : ''} */}
                                                </Col>
                                                <Col xl={6}>
                                                    <Button className="float-end rounded-pill" color='danger' onClick={() => createTeam()} >Manage Employee</Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className='mt-2'>
                                <Col xl={12}>
                                    <Card>
                                        <CardHeader>
                                            <span className="float-end text-secondary">{pricePerGB}/GB</span>
                                            <div className="form-check">
                                                {/* <Input
                                                    type="checkbox"
                                                    id="formCheck2"
                                                    checked={isStorageChecked}
                                                    onChange={() => handleCheckboxChange('storage')}
                                                /> */}
                                                <Label className="form-check-label fs-5" htmlFor="formCheck2">
                                                    Call  Recording Storage
                                                </Label>
                                            </div>
                                            <p className="text-muted mt-3">
                                                Enhance your Call Management experience by adding storage capacity in GB for seamless call recording.
                                            </p>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xl={6}>
                                                    {/* {isStorageChecked === true && ( */}
                                                    <div className="input-group">
                                                        <Label className="input-group-text">Storage</Label>
                                                        <select
                                                            className="form-select form-control"
                                                            onChange={handleStorageChange}
                                                            value={storage}
                                                        >
                                                            <option value="">Select storage</option>
                                                            {storageValues().map((value, index) => (
                                                                <>
                                                                    <option key={index} value={value}>
                                                                        {value} GB
                                                                    </option>
                                                                </>

                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='mt-3'>
                                                        <p>Available Storage space 33.50 GB out of 45 GB.</p>
                                                    </div>
                                                    {/* )}   */}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {/* <Col xl={6} className='mb-3'>
                                <Button className='mt-0' type='submit' color='danger' onClick={addonSubscriptionPlan}>Addon Subscription</Button>
                            </Col> */}
                        </Col>

                        <Col xxl={6}>
                            <Card>
                                <CardBody className="p-0">
                                    <div className="p-3 bg-soft-warning">
                                        <div className="float-end ms-2"></div>
                                        <h6 className="mb-0 text-danger">Subcription Summary</h6>
                                    </div>
                                    <div className="p-3">
                                        <div className="mt-3 pt-2">
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Number of Users</p>{adonData?.organizationStrength && adonData?.paymentPerUser ? `${adonData.organizationStrength} × ₹${adonData.paymentPerUser}` : '0'}
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{totalUsersAmount}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Call Recording Storage</p>{adonData?.totalStorage && pricePerGB ? `${adonData.totalStorage} GB × ₹${pricePerGB}` : '0'}
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{totalStorageAmount}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Total Taxable Amount</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{totalAmount}</h6>
                                                </div>
                                            </div>

                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">VAT {adonData?.vatPercentage}%</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{igstAmount || '0'}</h6>
                                                </div>
                                            </div>

                                            <div className="d-flex mb-2">
                                                <div className="flex-grow-1">
                                                    <p className="fs-13 mb-0">Total Payable Amount</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <h6 className="mb-0">₹{totalPayableAmount || '0'}</h6>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mt-3 pt-2">
                                            <a href={subPurchase} className="btn btn-danger w-100" target="_blank" rel="noreferrer">Continue</a>.
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <ModalManageUser addTeamModal={addTeamModal} setAddTeamModal={setAddTeamModal} />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddonPlan