import { APIClient } from "./api_helper";
import * as url from "./url_helper";
const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};


// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = data => api.create(url.POST_FAKE_REGISTER, data);

// Organization Login Method
export const postFakeLogin = data => api.create(url.POST_FAKE_LOGIN, data);

// Employee Login Method
export const postEmployeeFakeLogin = data => api.create(url.POST_EMPLOYEE_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = data => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Get Profile 
export const getProfiledata = () => api.get(`${url.POST_PROFILE}`)
export const getEmployeeProfiledata = () => api.get(`${url.EMPLOYEE_PROFILE}`)
// Edit profile
export const postJwtProfile = data => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data)
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};
// Login Method
export const postJwtLogin = data => api.create(url.POST_FAKE_JWT_LOGIN, data);
// postForgetPwd
export const postJwtForgetPwd = data => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);
// get Employee
export const getEmployeedata = () => api.get(`${url.GET_EMPLOYEE}`);
// Team
export const addTeamData = (team) => api.create(url.CREATE_TEAM, team);
export const employeeaddTeamData = (team) => api.create(url.EMPLOYEE_CREATE_TEAM, team);

export const updateTeamData = (team) => api.patch(url.UPDATE_TEAM, team);
export const employeeUpdateTeamData = (team) => api.patch(url.EMPLOYEE_UPDATE_TEAM, team);

export const deleteTeamData = (team) => api.delete(url.REMOVE_TEAM, team);
export const EmployeedeleteTeamData = (team) => api.delete(url.EMPLOYEE_REMOVE_TEAM, team);


export const getTeamData = (team) => api.get(url.GET_TEAM, team);
/* Industry Type LIST API START */
export const getIndustryType = () => api.get(`${url.POST_GET_INDUSTRY_TYPE}`);

//profile
// export const organizationProfileData = () => api.get(`${url.POST_PROFILE}`);
export const employeeProfileData = () => api.get(url.EMPLOYEE_PROFILE)