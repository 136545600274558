import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_EMPLOYEE_API_DATA} from "./actionType";

const EMPLOYEE_INIT_STATE = {
    employeeData: [],
    error: {},
}

 const EmployeeReducer = (state = EMPLOYEE_INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_EMPLOYEE_API_DATA:
                    return {
                        ...state,
                        employeeData: action.payload.data,
                    };
                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_EMPLOYEE_API_DATA:
                    return {
                        ...state,
                        error: action.payload.error,
                    };

                default:
                    return { ...state };
            }

        case GET_EMPLOYEE_API_DATA:
            return {
                ...state,
            };

        default:
            return { ...state };
    }

}

export default EmployeeReducer;
