export const toCamelCase = (str) => {
    return str
        .split(' ')
        .map((word, index) => {
            if (index === 0) {
                return word.toLowerCase();
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
};

export const sortTeamsByMemberCount = (teams, order = 'asc') => {
    return teams.sort((a, b) => {
        if (order === 'asc') {
            return a.memberCount - b.memberCount;
        } else if (order === 'desc') {
            return b.memberCount - a.memberCount;
        }
        return 0;
    });
};

export const sortMemberCount = (a, b) => {
    // Convert the memberCount to integers if they are not already numbers
    const memberCountA = parseInt(a.memberCount, 10);
    const memberCountB = parseInt(b.memberCount, 10);

    // Prioritize zeros
    if (memberCountA === 0 && memberCountB !== 0) {
        return -1;
    }
    if (memberCountB === 0 && memberCountA !== 0) {
        return 1;
    }

    // Sort normally
    return memberCountA - memberCountB;
};
