import React, { useState } from 'react';

//constants
import { layoutModeTypes } from "../../Components/constants/layout";
import { Link } from 'react-router-dom';
import whatsapp from "../../../src/assets/images/whatsapp.png";
import copyurl from "../../../src/assets/images/copyurl.png";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import facebooknew from "../../../src/assets/images/facebookm.png"
import instagramm from "../../../src/assets/images/instagramm.png"
import whatsappnew from "../../../src/assets/images/whatsappnew.png"
import mail from "../../../src/assets/images/mail.png"

const LightDark = ({ layoutMode, onChangeLayoutMode,users }) => {

    const mode = layoutMode === layoutModeTypes['DARKMODE'] ? layoutModeTypes['LIGHTMODE'] : layoutModeTypes['DARKMODE'];
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [text, setText] = useState('https://play.google.com/store/apps/details?id=com.salesninjacrm&hl=en');  // To store input text
    const [isCopied, setIsCopied] = useState(false);
    const closeBtn = (
        <button className="close" onClick={toggle} type="button">
            &times;
        </button>
    );
    const handleCopy = () => {
        navigator.clipboard.writeText(text).then(() => {
            setIsCopied(true);  // Set feedback to "Copied!"
            setTimeout(() => setIsCopied(false), 2000);  // Reset feedback after 2 seconds
        });
    };

    // For copy feedback

    // Function to copy text to clipboard
    // For copy feedback

    // Function to copy text to clipboard


    return (
        <div className="ms-1 header-item d-sm-flex  salen-ninja-modal-body-social">

            <div className='sn-sales-ninja-share-btn' onClick={toggle}>
                <a href='javascript:;' ><i className="ri-share-line"></i> Share</a>
            </div>

            <div className='sales-ninja-modal-share'>
                <Modal className='sales-body-modal-main' isOpen={modal} toggle={toggle}>
                    <ModalHeader className='sales-ninja-close-modal-btn' toggle={toggle} close={closeBtn}>
                        Invite Your Team to Sales Ninja !
                    </ModalHeader>
                    <ModalBody className='sales-ninja-modal-body-main'>

                        <p>Get your team connected with the Sales Ninja mobile app to start tracking calls and boosting productivity.
                            you can share the app with your employees:</p>

                        <div className='sales-ninja-modal-share-txt'>
                            <p><strong>1. Download the Sales Ninja App</strong></p>
                            <p><strong>2. Use Your Organization Code</strong>: Share the unique organization code {users?.organizationId} with your team to register under your organization.</p>
                            <p><strong>3. Quick and Easy Setup</strong>: Employees can simply enter the code during registration for instant access.</p>
                        </div>


                        <div className='sales-ninja-copyt-url-btn'>



                            <input
                                type="text"
                                value={text}
                                onChange={(e) => setText(e.target.value)}  // Update the input value in state
                                placeholder="Enter text to copy"
                                style={{ padding: '10px', }}
                            />
                            <button onClick={handleCopy} style={{ padding: '10px' }}>
                                {isCopied ? <i className="ri-file-copy-line"></i> : <i className="ri-file-copy-line"></i>}
                            </button>

                        </div>


                        <div className='salen-ninak-social-media-list'>
                            <h6>Share With your community</h6>
                            <ul>
                                {/* <li><a href="https://www.facebook.com/" target='_blank'><img src={facebooknew} /></a></li>
                                <li><a href="https://www.instagram.com/" target='_blank'><img src={instagramm} /></a></li> */}
                                <li><a href="https://web.whatsapp.com/" target='_blank'><img src={whatsappnew} /></a></li>
                                <li><a href="https://www.google.com/intl/en-US/gmail/about/" target='_blank'><img src={mail} /></a></li>
                            </ul>


                        </div>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="primary" onClick={toggle}>
                            Do Something
                        </Button>{' '}
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter> */}





                </Modal>


            </div>


            {/* <a href='https://web.whatsapp.com/' target='_blank' title='Share' className='salen-ninja-help-support-btn' style={{ marginRight: '20px' }}><img src={whatsapp} alt='Share on Whatsapp' /></a>
            <a href='https://play.google.com/store/apps/details?id=com.salesninjacrm&hl=en' title='Copy Url' className='salen-ninja-help-support-btn' style={{ marginRight: '20px' }}
                onClick={(e) => { e.preventDefault(); navigator.clipboard.writeText("https://play.google.com/store/apps/details?id=com.salesninjacrm&hl=en") }}
            > <img src={copyurl} alt='Share on Whatsapp' />
            </a> */}

            <a href='https://salesninjacrm.freshdesk.com/support/home' target='_blank' title='Support' className='salen-ninja-help-support-btn'><i className=' ri-customer-service-2-line'></i></a>
            {/* <button
                onClick={() => onChangeLayoutMode(mode)}
                type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                <i className='bx bx-moon fs-22'></i>
            </button> */}
            {/* <button onClick={myFunction} type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"><i className='bx bx-moon fs-22'></i></button> */}
        </div>
    );
};

export default LightDark;



