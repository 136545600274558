import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col, Row } from "reactstrap";


const DashboardEmployeeSummary = ({ piechartData, EmployeeDefault }) => {

  const uniqueClient = parseInt(piechartData?.uniqueClient);
  const avgCallDurationInSeconds = piechartData.averageCallDuration;
  //  const formatDuration  =  new Date(avgCallDurationInSeconds* 1000)
  //                     .toISOString()
  //                     .slice(11, 19)
  const formatDuration = (duration) => {
    const seconds = Math.floor(duration % 60);
    const minutes = Math.floor((duration / 60) % 60);
    const hours = Math.floor(duration / 3600);
    return [hours, minutes, seconds].map(v => String(v).padStart(2, '0')).join(':');
  };

  return (
    <React.Fragment>
      <Row className="sn-avg-unique-mumber-call">
        <Col lg={4} md={6} xs={12}>
          <Card className="avg-call-duration">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className="avatar-title text-primary rounded-circle fs-3"
                    style={{ backgroundColor: "#16cbbd1c" }}
                  >
                    <i className="ri-numbers-line " style={{color:'#16cbbd'}}></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <p
                    className="text-capitalize  fs-12  mb-1"
                    style={{ fontWeight: "900" }}
                  >
                    Avg Call Duration
                  </p>
                  <h4 className="mb-0 fw-bold">
                    <CountUp
                      start={0}
                      end={avgCallDurationInSeconds}
                      separator={piechartData?.separator}
                      duration={3}
                      style={{ color: "#495057e0" }}
                      formattingFn={formatDuration}
                    />
                  </h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg={4} md={6} xs={12}>
          <Card className="avg-unique-call ">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className="avatar-title text-primary rounded-circle fs-3"
                    style={{ backgroundColor: "#f244b614" }}
                  >
                    <i className="las la-phone-volume " style={{color:'#f244b6'}}></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <p
                    className="text-capitalize fs-12 mb-1"
                    style={{ fontWeight: "900" }}
                  >
                    Unique Call
                  </p>
                  <h4 className="mb-0 fw-bold">
                    {" "}
                    <CountUp
                      start={0}
                      end={uniqueClient}
                      decimals={piechartData?.decimal}
                      separator={piechartData?.separator}
                      duration={3}
                      style={{ color: "#495057e0" }}
                    />
                  </h4>
                </div>
              </div>
            </CardBody>

            {/* <img src={uniqueCall} alt="" style={{ filter: 'blur(2px)' }} />} */}
          </Card>
        </Col>

        <Col lg={4} md={6} xs={12}>
          <Card className="number-of-employee">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className="avatar-title  text-primary rounded-circle fs-3"
                    style={{ backgroundColor: "#068dc60f" }}
                  >
                    <i className="ri-pie-chart-2-line" style={{color:'#068dc6'}}></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <p
                    className="text-capitalize fs-12  mb-1"
                    style={{ fontWeight: "900" }}
                  >
                    Number Of Employees
                  </p>
                  <h4 className="mb-0 fw-bold">
                    {" "}
                    <CountUp
                      start={0}
                      end={EmployeeDefault.length}
                      decimals={piechartData?.decimal}
                      separator={piechartData?.separator}
                      duration={3}
                      style={{ color: "#495057e0" }}
                    />
                  </h4>
                </div>
              </div>
            </CardBody>
            {/* <img src={employee} alt="" style={{ filter: 'blur(2px)' }} /> */}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DashboardEmployeeSummary;





export { DashboardEmployeeSummary }