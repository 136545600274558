import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";
//import images
import logoLight from "../../../assets/images/logo-light.png";
import salescrm from "../../../assets/images/sales-crm.png";
import { useLocation } from 'react-router-dom';

const SuccessMsg = () => {
    const location = useLocation();
    document.title = "Success Message | Velzon - React Admin & Dashboard Template";
    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-0 text-white-50">
                                        <div>
                                            <Link to="/dashboard" className="d-inline-block auth-logo">
                                                {/* <img src={salescrm} alt="" height="46" /> */}
                                            </Link>
                                        </div>
                                        {/* <p className="mt-3 fs-15 fw-semibold">Sales Ninja</p> */}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col md={10} lg={8} xl={6}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4 text-center">
                                            <div className="avatar-lg mx-auto mt-2">
                                                <div className="avatar-title bg-light text-primary display-3 rounded-circle">
                                                    <i className="ri-mail-line"></i>
                                                </div>
                                            </div>
                                            <div className="mt-4 pt-2">
                                                <h4>Well done !</h4>
                                                <h2 className='fw-1800 fs-7'>Thank you for signing up </h2>
                                                <p className="text-muted">A welcome email will be sent to <span className="fw-bold fs-6">{location?.state?.email}</span> shortly, <br/>containing a verification link that enables you to sign in.<br/>
                                                    if you don't receive an email from us within  a couple of minutes,<br/>please check your junk / spam folder.</p>
                                                <div className="mt-4">
                                                    <Link to="/login" className="btn btn-success w-0">Back to Sign in</Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment >
    )
}

export default SuccessMsg