
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import ParticlesAuth from '../../pages/AuthenticationInner/ParticlesAuth';
const PaymentSuccessMsg = () => {
const navigate = useNavigate()
    const handleClick = () => {
        navigate('/invoices')
    }
    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>

                                            {/* <Link to="/dashboard" className="d-inline-block auth-logo">
                                        <img src={logoLight} alt="" height="20" />
                                    </Link> */}
                                        </div>
                                        {/* <p className="mt-3 fs-15 fw-semibold">Premium Admin & Dashboard Template</p> */}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col md={10} lg={8} xl={7}>
                                    <Card className="mt-4">
                                        <CardBody className="p-0 text-center" style={{ height: '350px' }}>
                                            <div className="avatar-lg mx-auto mt-0">
                                                {/* <div className="avatar-title bg-light text-success display-3 rounded-circle"> */}
                                                <img src="https://i.ibb.co/Njn6Zjw/success-icon.png" alt="" style={{ width: '100%', maxWidth: '150px', marginTop: '40px' }} />
                                                {/* <i className="ri-checkbox-circle-fill"></i> */}
                                                {/* </div> */}
                                            </div>
                                            <div className="mt-5 pt-2">
                                                <h2>Payment succesfull !</h2>
                                                <p>Your order ID : succe_FX2k7hnghkl</p>
                                                <small>Thank you for your payment! We are pleased to inform you that your  transaction has <br/> been successfully completed.</small>
                                                <div className="mt-4">
                                                    <Button type="submit" className="btn btn-danger" onClick={handleClick}>
                                                    Continue
                                                    </Button>
                                             
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment >
    )
}

export default PaymentSuccessMsg