import { all, fork } from "redux-saga/effects";
import LayoutSaga from "./layouts/saga";
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import teamSaga from "./team/saga";
import authEmployeeSaga from "./getEmployee/saga";
import authIndustrySaga from "./CallType/saga";
import teamSagas from "./teams/saga";

export default function* rootSaga() {
  yield all([
    fork(authEmployeeSaga),
    fork(authIndustrySaga),
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(teamSaga),
    fork(teamSagas),
  ]);
}
