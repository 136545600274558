import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Label, Row } from "reactstrap";
import { POST_CONTACT_US, POST_GET_INDUSTRY_TYP, POST_GET_INDUSTRY_TYPE } from '../../../../helpers/url_helper';
import axios from "axios";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';


const ContactUs = () => {
    const phoneNumberRules = /^[1-9]\d{9}$/;
    const emailRules = /^[a-zA-Z0-9._%+-]+@(gmail|yahoo)\.com$/;

    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const [industryTypeData, setIndustryTypeData] = useState()
    const { handleSubmit, errors, touched, getFieldProps } = useFormik({
        initialValues: {
            username: "",
            email: "",
            contactNo: "",
            companyName: "",
            companyStrength: "",
            rol: '',
            code: "",
            terms: false
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please enter your username").min(1, "Must have a character").max(25, "Must be shorter than 25"),
            email: Yup.string().email("Please enter a valid email address").matches(emailRules, { message: "  Invalid email" }).required("Please enter your email"),
            contactNo: Yup.string().matches(phoneNumberRules, { message: "Please enter 10 digit mobile number" }).required("Please enter your mobile no"),
            companyName: Yup.string().required("Company name is required"),
            companyStrength: Yup.string().required("Company strength is required"),
            terms: Yup.boolean().isTrue('You must accept the terms!'),
            rol: Yup.string().required('Name must be filled out"'),
            code: Yup.string().required('Counrty Code must be filled out"'),
        }),
        onSubmit: value => {
            let data = JSON.stringify({
                ownerName: value.username,
                email: value.email,
                contactNo: value.code + value.contactNo,
                companyName: value.companyName,
                companyStrength: value.companyStrength,
                industryType: value.rol,
            });

            let config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL + POST_CONTACT_US,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)
                .then((response) => {
                    if (response?.success) {
                        toast(response?.message)
                        setSuccessMessage(response?.message);
                        setSuccess(response?.success);
                    }
                })
                .catch((error) => {
                    console.log(error, "err");
                });
        }
    });
    useEffect(() => {
        fetchUserData()
    }, [])
    const fetchUserData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}${POST_GET_INDUSTRY_TYPE}`)

            setIndustryTypeData(res?.data)
        }
        catch (error) {
            console.log("error", error)
        }
    }

    return (
        <>
            <section className="section" id="contact">
                <ToastContainer />
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <section className="s_text_block pt40 pb40 o_colored_level bg-100 " data-snippet="s_text_block">
                                <div className="container s_allow_columns">
                                    <div className="row">
                                        <div className="col-lg-8 mt-4 mt-lg-0">
                                            <h1 className="home3-hero-content">Contact us</h1>
                                            <section className="s_website_form mt-3" data-snippet="s_website_form">
                                                <div className="container">
                                                    <form id="contactus_form" className="o_mark_required" noValidate onSubmit={handleSubmit}>
                                                        <div className="s_website_form_rows row s_col_no_bgcolor">
                                                            <div className="mb-0 py-2 col-12 s_website_form_field s_website_form_custom s_website_form_required" data-type="char" data-name="Field">
                                                                <div className="s_col_no_resize s_col_no_bgcolor">
                                                                    <Row>
                                                                        <Col sm={3}>
                                                                            <label className="col-form-label col-sm-auto s_website_form_label" style={{ width: '200px' }} htmlFor="contact1">
                                                                                <span className="s_website_form_label_content">Name</span>
                                                                                <span className="s_website_form_mark"> *</span>
                                                                            </label>
                                                                        </Col>
                                                                        <Col>
                                                                            <input
                                                                                pattern="[A-Za-z]+"
                                                                                title="Only letters are allowed"
                                                                                type="text"
                                                                                name='username'
                                                                                placeholder="Name"
                                                                                {...getFieldProps('username')}
                                                                                className={`form-control s_website_form_input ${(touched.username && errors.username) && 'error_input'}`}
                                                                            />
                                                                            {(touched.username && errors.username) && <span className="text-danger error">{errors.username}</span>}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            <div className="mb-0 py-2 col-12 s_website_form_field s_website_form_custom" data-type="char" data-name="Field">
                                                                <div className="s_col_no_resize s_col_no_bgcolor">
                                                                    <Row>
                                                                        <Col sm={3}>
                                                                            <label className="col-form-label col-sm-auto s_website_form_label" style={{ width: '200px' }} htmlFor="contact2">
                                                                                <span className="s_website_form_label_content">Contact Number</span>
                                                                            </label>
                                                                        </Col>
                                                                        <Col sm={5}>
                                                                            <select className="form-select" name="code" {...getFieldProps('code')}>
                                                                                <option value="">Select countryList</option>
                                                                                {industryTypeData?.countryList.map((ele, key) => {
                                                                                    return (
                                                                                        <>
                                                                                            <option key={key} value={ele?.code}>{ele?.code}  {ele?.name}</option>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                            {(touched.code && errors.code) && <span className="text-danger error">{errors.code}</span>}
                                                                        </Col>
                                                                        <Col>
                                                                            <input
                                                                                name="contactNo" type="number"
                                                                                placeholder="Contact Number"
                                                                                {...getFieldProps('contactNo')}
                                                                                className={`form-control s_website_form_input ${(touched.contactNo && errors.contactNo) && 'error_input'}`}
                                                                            />
                                                                            {(touched.contactNo && errors.contactNo) && <span className="text-danger error">{errors.contactNo}</span>}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            <div className="mb-0 py-2 col-12 s_website_form_field s_website_form_required s_website_form_model_required" data-type="email" data-name="Field">
                                                                <div className="s_col_no_resize s_col_no_bgcolor">
                                                                    <Row>
                                                                        <Col sm={3}>
                                                                            <label className="col-form-label col-sm-auto s_website_form_label" style={{ width: '200px' }} htmlFor="contact3">
                                                                                <span className="s_website_form_label_content">Email Address</span>
                                                                                <span className="s_website_form_mark"> *</span>
                                                                            </label>
                                                                        </Col>
                                                                        <Col>
                                                                            <input
                                                                                type="email"
                                                                                placeholder="Email Address"
                                                                                {...getFieldProps('email')}
                                                                                className={`form-control s_website_form_input ${(touched.email && errors.email) && 'error_input'}`}
                                                                            />
                                                                            {(touched.email && errors.email) && <span className="text-danger error">{errors.email}</span>}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            <div className="mb-0 py-2 col-12 s_website_form_field s_website_form_custom" data-type="char" data-name="Field">
                                                                <div className="s_col_no_resize s_col_no_bgcolor">
                                                                    <Row>
                                                                        <Col sm={3}>
                                                                            <label className="col-form-label col-sm-auto s_website_form_label" style={{ width: '200px' }} htmlFor="contact4">
                                                                                <span className="s_website_form_label_content">Company Name</span>
                                                                            </label>
                                                                        </Col>
                                                                        <Col>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Company Name"
                                                                                {...getFieldProps('companyName')}
                                                                                className={`form-control s_website_form_input ${(touched.companyName && errors.companyName) && 'error_input'}`}
                                                                            />
                                                                            {(touched.companyName && errors.companyName) && <span className="text-danger error">{errors.companyName}</span>}

                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            <div className="mb-0 py-2 col-12 s_website_form_field s_website_form_custom" data-type="char" data-name="Field">
                                                                <div className="s_col_no_resize s_col_no_bgcolor">
                                                                    <Row>
                                                                        <Col sm={3}>
                                                                            <label className="col-form-label col-sm-auto s_website_form_label" style={{ width: '200px' }} htmlFor="contact2">
                                                                                <span className="s_website_form_label_content">Team Size</span>
                                                                            </label>
                                                                        </Col>
                                                                        <Col>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Company Strength"
                                                                                {...getFieldProps('companyStrength')}
                                                                                className={`form-control s_website_form_input ${(touched.companyStrength && errors.companyStrength) && 'error_input'}`}
                                                                            />
                                                                            {(touched.companyStrength && errors.companyStrength) && <span className="text-danger error">{errors.companyStrength}</span>}

                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            <div className="mb-0 py-2 col-12 s_website_form_field s_website_form_required s_website_form_model_required" data-type="char" data-name="Field">
                                                                <div className="s_col_no_resize s_col_no_bgcolor">
                                                                    <Row>
                                                                        <Col sm={3}>
                                                                            <label className="col-form-label col-sm-auto s_website_form_label" style={{ width: '200px' }} htmlFor="contact5">
                                                                                <span className="s_website_form_label_content">Select Industry</span>
                                                                                <span className="s_website_form_mark"> *</span>
                                                                            </label>
                                                                        </Col>
                                                                        <Col>

                                                                            <select id="rol" {...getFieldProps('rol')} className="form-select" >
                                                                                <i className="bi bi-chevron-down"></i>
                                                                                <option value="">Select Industry</option>
                                                                                {(industryTypeData?.industryType || [])?.map((ele, index) => {

                                                                                    return (
                                                                                        <>
                                                                                            <option key={ele} value={ele} >{ele}</option>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                            {(touched.rol && errors.rol) && <span className="text-danger error">{errors.rol}</span>}

                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            <div className="mb-0 py-2 col-12 s_website_form_field s_website_form_required s_website_form_model_required" data-type="char" data-name="Field">
                                                                <div className="col-sm" style={{ marginLeft: '180px' }}>
                                                                    <input id='leadTermCheck' type="checkbox" {...getFieldProps('terms')} />&nbsp;
                                                                    <label htmlFor="Business" className="custom-control-label dt-cursor"> <Label for='leadTermCheck'>Yes, I understand and agree to the Sales Ninja</Label> <a href="/privacy" target="_blank">Privacy Policy</a></label><br />

                                                                    {(touched.terms && errors.terms) && <span className="text-danger error">{errors.terms}</span>}
                                                                </div>
                                                            </div>

                                                            <div className="mb-0 py-2 col-12 s_website_form_submit" data-name="Submit Button">
                                                                <div style={{ width: '200px' }} className="s_website_form_label" />
                                                                <button className="btn btn-primary btn-lg s_website_form_send" type="submit">Submit</button>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ContactUs
