import { APIClient } from "../../helpers/api_helper";
import { GET_EMPLOYEE } from "../../helpers/url_helper";
import dayjs from "dayjs";

const api = new APIClient();

const getInitialDates = () => {
  return [dayjs().subtract(0, "days"), dayjs()];
};

const getDaysAgo = () => {
  const fromDate = new Date();
  fromDate.setFullYear(2024);
  fromDate.setMonth(1);
  fromDate.setDate(8);
  return fromDate.toISOString().slice(0, 10);
};

const getCurrentDate = () => {
  return new Date().toISOString().slice(0, 10);
};

function PreviousDate() {
  const currentDate = new Date();
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 0);

  const year = previousDate.getFullYear();
  const month = String(previousDate.getMonth() + 1).padStart(2, '0');
  const day = String(previousDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function CurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function getPreviousDate() {
  const currentDate = new Date();
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 0);
  return previousDate;
}

function setCurrentDate() {
  const currentDate = new Date();
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate());
  return previousDate;
}

const getAllEmployee = async () => {
  try {
    const response = await api.get(`${GET_EMPLOYEE}`);
    if (response?.success === true) {
      const apiResponse = response.data;
      const arnOption = apiResponse?.map(employee => ({
        id: `${employee?._id}`
      }));
      return arnOption;
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

const storageValues = () => { return [5, 10, 15, 20, 25, 30]; };

export {
  getDaysAgo, setCurrentDate, getPreviousDate, getCurrentDate,
  getAllEmployee, storageValues, PreviousDate, CurrentDate, getInitialDates
}