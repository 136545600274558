import React from 'react'
import facebook from "../../assets/images/facebook.png"
const Facebook = () => {
  return (
    <>
       <img src={facebook} alt="logo" />
    </>
  )
}

export default Facebook
