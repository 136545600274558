import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { NOT_PICKED_REPORT } from '../../helpers/url_helper';
import CommanDataTable from '../../Components/Common/DataTable';
import moment from 'moment';
import { DatePickerWithoutTimes } from '../../Components/Common/Flatpicker';
import TeamSelect from '../../Components/Common/TeamSelect';
import EmployeeSelect from '../../Components/Common/EmployeeSelect';
import { formatDate } from '../../Components/Common/FormDateTime';
import { getTeamEmployeeIncludeApi } from '../../store/dashboardCallCrm/getTeamEmployee/action';
import { useDispatch } from 'react-redux';
import { APIClient } from "../../helpers/api_helper";
import { getPreviousDate, setCurrentDate } from '../../Components/Common/DefaultDateSet';
import { HandleErrorMessage } from '../../Components/Common/HandleErrorMessage';
const api = new APIClient();

const NeverPickepReport = () => {
    document.title = "Not Pickup by Client";

    const permissionType = JSON.parse(localStorage.getItem("permission"));
    const viewReportPermission = permissionType?.includes("VIEW_REPORT");
    const loginType = JSON.parse(localStorage.getItem("logintype"))

    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const previewsDate = getPreviousDate()
    const currentDate = setCurrentDate()
    const [EmployeeData, setEmployeeData] = useState([])

    //search field state
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState('');

    // date picker state
    const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
    const [selectedToDate, setSelectedToDate] = useState(currentDate);

    //select team state
    const [teamIndu, setTeamIndu] = useState([])

    // employee select 
    const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);

    // Pagination settings
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    // table data state
    const [notPickupedData, setNotPickupedData] = useState()

    // flatPicker Date
    const formattedStartDate = formatDate(selectedFromDate);
    const formattedEndDate = formatDate(selectedToDate);

    // From Date
    const handleFromDateChange = (dateStr) => {
        setSelectedFromDate(dateStr);
    }

    // To date
    const handleToDateChange = (dateStr) => {
        setSelectedToDate(dateStr)
    }



    useEffect(() => {
        let teamType = teamIndu?.map(item => item?.value) || [];
        if (teamType?.length > 0) {
            const newTeam = { teamName: teamType };
            dispatch(getTeamEmployeeIncludeApi(newTeam));
        }
    }, [teamIndu, dispatch])


    const column = [
        {
            name: <span className='font-weight-bold fs-13 text-center fs-2 fs-2' style={{ textTransform: 'capitalize' }}>Employee</span>,
            selector: row => row?.employeeName,
            cell: row => (
                <div className='mt-2 mb-2'>
                    <h5 className='font-weight-bold fs-13' style={{ textTransform: 'capitalize' }}>{row?.employeeName}</h5>
                    <h6 className='text-center- font-weight-bold fs-13'>{row?.employeeNumber}</h6>
                </div>
            ),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13 text-center fs-2 fs-2'>Client Name</span>,
            selector: row => row?.clientName,
            cell: row => (
                <div className='mt-2 mb-2'>
                    <h5 className='font-weight-bold fs-13' style={{ textTransform: 'capitalize' }}>{row?.clientName}</h5>
                    <h6 className='text-center- font-weight-bold fs-13'>{row?.phoneNumber}</h6>
                </div>
            ),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13 fs-2'>Date</span>,
            selector: row => moment(row.date).format("DD MMM YYYY"),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13 fs-2'>Time</span>,
            selector: row => moment(row?.time).format("hh:mm A"),
            // sortable: true
        },
        // {
        //     name: <span className='font-weight-bold fs-13 text-center fs-2'>Duration</span>,
        //     selector: row => row.duration,
        //     sortable: true
        // },
        {
            name: <span className='font-weight-bold fs-13 fs-2'>Call Type</span>,
            selector: row => <div><i className="mdi mdi-phone-outgoing sn-out-going-text" /> <span className="text-warning gap-2 sn-out-going-call">{row.type}</span></div>,
        },
        {
            name: <span className='font-weight-bold fs-13 text-center fs-2'>Note</span>,
            selector: row => row.note,
        },
        // {
        //     name: <span className='font-weight-bold fs-13 text-center fs-2'>Recording Status</span>,
        //     selector: row => <span className="text-info gap-2">{row.recordingStatus}</span>,
        // },


    ];



    const handleSubmit = () => {
        const updatedIds = selectedEmployeeOptions.map(userid => userid?.id);
        notPickedData(currentPage, perPage, "", updatedIds)
        setCurrentPage(1)
        setPerPage(10);
    }


    // Debounce search query
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [query]);


    useEffect(() => {
        const fetchData = async () => {
            if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
                await notPickedData(currentPage, perPage, debouncedQuery);
            }
        };

        fetchData();
    }, [debouncedQuery, currentPage, perPage]);


    // Search field handler
    const handleSearch = (e) => {
        setQuery(e.target.value);
    };

    const handlePageChange = page => {
        setCurrentPage(page);
        notPickedData(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        notPickedData(page, newPerPage)
        setPerPage(newPerPage);
    }


    const notPickedData = async (page, size = perPage, searchTerm = '') => {
        setIsLoading(true);
        try {
            const updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
            let data = {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                employeeIds: updatedIds
            };
            const response = await api.create(`${NOT_PICKED_REPORT}?pg=${page}&lm=${size}&query=${searchTerm}`, data);
            if (response && response?.success === true) {
                setIsLoading(false)
                const apiResponse = response?.data
                const tableData = (apiResponse?.paginatedResults || []).map((data, index) => {
                    const recordingUserStatus = data?.recordingStatus?.replace(/_/g, ' ');
                    return {
                        employeeName: Array.isArray(data?.employeeDescription) ? data.employeeDescription[0]?.fullName : 'unknown',
                        employeeNumber: Array.isArray(data?.employeeDescription) ? data.employeeDescription[0]?.mobileNumber : '-',
                        clientName: data?.name ?? 'unknown',
                        phoneNumber: data?.phoneNumber ?? '-',
                        date: data?.dateTime ?? '-',
                        time: data?.dateTime ?? '-',
                        duration: data?.duration ?? '-',
                        note: data?.note ?? '-',
                        recordingStatus: recordingUserStatus ?? '-',
                        type: data?.type ?? '-'
                    };
                });
                setNotPickupedData(tableData)
                setTotalRows(apiResponse?.totalCount);
            }
        } catch (error) {
            HandleErrorMessage(error);
        } finally {
            setIsLoading(false);
        }
    }



    //clear button function
    const resetInputHistory = () => {
        setSelectedToDate('')
        setSelectedFromDate('')
        setSelectedEmployeeOptions([]);
        setTeamIndu([]);
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title='Not Pickup by Client' pageTitle="Not Pickup by Client" />
                    <Row>
                        <Col lg={12}>
                            <Card className='h-100'>
                                {/* <CardHeader>
                                    <h4 className="card-title mb-0">Not Pickup by Client</h4>
                                </CardHeader> */}
                                <CardBody>
                                    <Row className='sn-header-select-employess-home sn-top-inputs-heading'>
                                        <div className='col-md-2 col-sm-2'>
                                            <DatePickerWithoutTimes onChange={handleFromDateChange} getDate={selectedFromDate} labelContent='From Date' />
                                        </div>

                                        <div className='col-md-2 col-sm-2'>
                                            <DatePickerWithoutTimes onChange={handleToDateChange} getDate={selectedToDate} labelContent='To Date' />
                                        </div>

                                        {((viewReportPermission && loginType === 'employee') || loginType === 'organization') && <div className='col-md-2 col-sm-12'>
                                            <TeamSelect setSelectedEmployeeOptions={setSelectedEmployeeOptions} teamIndu={teamIndu} setTeamIndu={setTeamIndu} labelContent='Select Team' />
                                        </div>}

                                        {((viewReportPermission && loginType === 'employee') || loginType === 'organization') && <div className='col-md-3 col-sm-12'>
                                            <EmployeeSelect EmployeeDefault={EmployeeData} setEmployeeDefault={setEmployeeData} teamIndu={teamIndu} selectedEmployeeOptions={selectedEmployeeOptions}
                                                setSelectedEmployeeOptions={setSelectedEmployeeOptions} labelContent='Select Employee' />
                                        </div>}
                                        <div className='col-md-3 col-sm-12 '>
                                            <FormGroup className='mt-2'>
                                                <Label for="exampleSelect"></Label>
                                                <div id="exampleSelect" className='sn-call-apply-btn-reset '>
                                                    <Button
                                                        id="exampleSelect"
                                                        type="submit"
                                                        color="danger"
                                                        className=""
                                                        onClick={handleSubmit}
                                                        disabled={isLoading}
                                                    >
                                                        Apply
                                                    </Button>
                                                    <Button
                                                        className=""
                                                        style={{ marginLeft: "10px" }}
                                                        id="exampleSelect"
                                                        outline
                                                        color="danger"
                                                        onClick={resetInputHistory}
                                                        disabled={isLoading}
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Row>
                                    <Row>
                                    </Row>
                                    <Row className='sn-team-managment-tble sn-user-tble-manin-manage'>
                                        <Col lg={12}>
                                            <CommanDataTable handleSearch={handleSearch}
                                                searchText={query}
                                                column={column} tableData={notPickupedData}
                                                handlePerRowsChange={handlePerRowsChange}
                                                totalRows={totalRows} currentPage={currentPage}
                                                isLoading={isLoading}
                                                handlePageChange={handlePageChange}
                                                noData='There are no records to display'
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}

export default NeverPickepReport








