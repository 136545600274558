import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, Button } from 'reactstrap';
import PropTypes from "prop-types";
import CommonInput from './CommonInput';

const CommonModal = ({ isOpen, toggle, formik, modalTitle, buttonText, className }) => {
    return (
        <React.Fragment>
            <Row>
                <Modal isOpen={isOpen} toggle={toggle}>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>{modalTitle}</ModalHeader>
                    <Form onSubmit={formik.handleSubmit} className="needs-validation" action="#">
                        <ModalBody>
                            <Row>
                                <Col sm={12}>
                                    <CommonInput
                                        label="Team Name"
                                        name="teamName"
                                        type="text"
                                        placeholder="Enter Team Name"
                                        formik={formik}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button type="submit" className={`btn ${className}`}>{buttonText}</Button>
                            </div>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Row>
        </React.Fragment>
    )
}

CommonModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any,
    className: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
}

CommonModal.defaultProps = {
    color: 'primary',
    className: 'btn-primary',
    disabled: false,
};

export default CommonModal 